<template>
  <div>
    <v-card min-height="calc(90vh-1)" max-height="calc(90vh-1)">
      <CollapsableList
        :items="localItems"
        item-key="id"
        :item-title="itemTitle"
        :item-subtitle="itemSubTitle"
        click-mode="expandOnly"
      >
        <template v-slot:expansion="{ item: container }">
          <div style="font-size: 20px" class="ma-2" flat>
            <v-btn :color="container.color" icon>
              <v-icon> mdi-clipboard</v-icon></v-btn
            >
          </div>
          <ResponsiveCardSelector
            v-model="selectedMenuItem"
            name="MenuItemContainer"
            class="d-flex justify-center align-center"
            :items="container.items"
            :returnObject="true"
            item-key="id"
            card-max-height="20"
            card-min-height="20"
            card-max-width="180"
            card-min-width="180"
            :multiple="false"
            @click="handleSelectedMenuItem($event)"
          >
            <template v-slot:item="{ item, cardMinHeight }">
              <div
                class="over d-flex flex-column justify-center align-center"
                :style="{
                  'min-height':
                    cardMinHeight.indexOf('px') != -1
                      ? cardMinHeight
                      : cardMinHeight + 'px',
                  'background-color': container.color,
                }"
              >
                <div class="ma-2 font-weight-medium">
                  {{ item.description }}
                </div>
                <div class="font-weight-medium">€ {{ item.priceSelling }}</div>
              </div>
            </template>
          </ResponsiveCardSelector>
        </template>
      </CollapsableList>
    </v-card>
  </div>
</template>

<script>
import CollapsableList from "@/components/common/CollapsableList.vue";
import ResponsiveCardSelector from "@/components/common/ResponsiveCardSelector";

export default {
  name: "CollapsableSelectionList",
  components: { CollapsableList, ResponsiveCardSelector },
  props: {
    items: {
      type: Array,
      default: () => [],
    },
    itemKey: {
      type: String,
      default: "id",
    },
    itemIcon: {
      type: String,
      default: undefined,
    },
    itemTitle: {
      type: String,
      default: "name",
    },
    itemSubTitle: {
      type: String,
      default: "description",
    },
  },
  data() {
    return {
      selectedMenuItem: undefined,
      localItems: this.items,
    };
  },
  methods: {
    handleSelectedMenuItem(item) {
      this.$emit("item-selected", item);
    },
  },
  watch: {
    items(newVal) {
      this.localItems = newVal;
    },
  },
};
</script>

<style>
</style>