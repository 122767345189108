<template>
  <div style="overflow-x: hidden; overflow-y: hidden" class="ml-8">
    <v-row no-gutters class="py-1">
      <v-col cols="auto">
        <LeftSideMenuBar
          v-model="selectedItem"
          :items="filteredMenuItems"
        ></LeftSideMenuBar>
      </v-col>
      <v-col style="margin-left: 2rem;margin-right 10 rem;">
        <v-select
          :items="items"
          v-model="keyvalue"
          filled
          label="Seleziona Chiave"
          class="half-width"
          dense
          append-icon="mdi-key-wireless"
          @change="sendKey()"
        ></v-select>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import { SearchWithButton } from "likablehair-ui-components";
import LeftSideMenuBar from "@/components/common/LeftSideMenu.vue";

import departmentService from "@/services/departments/departments.service.js";

export default {
  name: "GeneralManagement",

  components: {
    SearchWithButton,
    LeftSideMenuBar,
  },

  data: () => ({
    keyvalue: "",
    items: [],
    rules: [
      (value) => !!value || "Required.",
      (value) => (value && value.length >= 3) || "Min 3 characters",
    ],
    selectedItem: {},
    menuItems: [
      {
        title: "Inserimento Chiavi",
        name: "general",
        icon: "mdi-key-plus",
        component: "General",
      },
    ],
  }),

  mounted() {
    this.checkKeyAlreadypresent();
    this.fetchItems();
  },

  methods: {
    sendKey() {
      if (this.keyValid)
        this.$settings.set("nameDepartmentForBar", this.keyvalue);
      else 
        this.$delegates.snackbar("Selezionare una chiave valida");
    },
    fetchItems() {
      departmentService
        .list()
        .then((resolve) => {
          this.items = resolve.rows.map((department) => {
            return department.name;
          });
        })
        .catch((error) => {
          console.log(error);
        });
    },
    checkKeyAlreadypresent() {
      this.$settings
        .get("nameDepartmentForBar")
        .then((result) => {
          if (result.value) this.keyvalue = result.value;
        })
        .catch((error) => {
          console.log(error);
        });
    },
  },
  computed: {
    keyValid() {
      return this.keyvalue && this.keyvalue.length >= 3;
    },
    filteredMenuItems: function () {
      let menuItems = [...this.menuItems];

      return menuItems;
    },
  },
};
</script>

<style scoped>
.half-width {
  width: 50%;
  min-width: 400px;
}
</style>