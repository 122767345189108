<template>
  <div>
    <GridLayoutComponent
      :background-color="localZoneSelected.zone.color"
      :elements="localZoneSelected.tables"
      :margin-element="[10, 10]"
      :element-height="30"
      :vertical="true"
      :getColor="getColor"
      :splittable="splittable"
      :draggable="joinable"
      :joinable="joinable"
      @join-element="joinElement"
    >
      <template v-slot:item-content="el">
        <div
          @click="$emit('handle-selection-element', el)"
          class="text d-flex justify-center align-center"
        >
          <div>
            {{ el.i }}
          </div>
        </div>
      </template>
    </GridLayoutComponent>
  </div>
</template>
<script>
import GridLayoutComponent from "@/components/common/GridLayoutComponent.vue";

export default {
  components: {
    GridLayoutComponent,
  },
  mounted() {
    this.bus.$on('join-tables', el => {
      this.localZoneSelected.tables = this.localZoneSelected.tables.map(el => {
        el.static = true;
        return el
      })
      this.joinable = true
    })
    
    this.bus.$on('split-tables', el => {
      this.splittable= true
    })
  },
  props: {
    zoneSelected: {
      type: Object,
      default: () => {},
    },
    bus: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      localZoneSelected: this.zoneSelected,
      joinable: false,
      splittable: false,
      elementsToJoin: [],
      localElements: [],
    };
  },
  watch: {
    zoneSelected(newVal) {
      this.localZoneSelected = newVal;
    },
  },
  methods: {
    getColor(el) {
      if(!!el.currentBillId)
        return "#DA3E52";
      else if(!!el.reservations)
        return "#E9AE60"
      else
        return "#69B685"
    },
    joinElement(idEl){
      this.elementsToJoin.push(idEl)
    }
  },
};
</script>


<style scoped lang="scss">
.booked {
  color: black;
  border-radius: 23px;
  background: #E9AE60;
}
.free {
  color: black;
  border-radius: 23px;
  background: #69B685;
}
.occupied {
  color: black;
  border-radius: 23px;
  background: #DA3E52;
}
</style>