<template>
  <StandardDialog
    dialog-width="90vw"
    dialog-height="calc(90vh-1)"
    v-model="localOpenMenu"
    persistent
    :title="reservationTitle"
  >
    <v-row class="mt-4">
      <v-col cols="8">
        <v-row>
          <v-col cols="5">
            <DetailReservation
              max-height="30vh"
              min-height="30vh"
              v-if="reservationSelected"
              :current-reservation-id="
                reservationSelected ? Number(reservationSelected.id) : 0
              "
            >
            </DetailReservation>
          </v-col>
          <v-col cols="7">
            <ListItemComponent
              title="Prodotti Ordinati"
              color=" light-green lighten-5"
              :items="localProvidedItems"
              :item-description="(item) => !!item.serviceId ? item.service.name : item.item.description"
              :item-price="(item) => !!item.serviceId ? item.service.price : item.item.priceSelling"
              :item-quantity="(item) => item.quantity"
              height="30vh"
              min-height="30vh"
              max-height="30vh"
              :show-actions="true"
              @add-item="addItem"
              @remove-item="removeItem"
              @delete-item="deleteItem"
            >
            </ListItemComponent>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12">
            <CollapsableSelectionList
              :items="menus"
              @item-selected="handleSelectedMenuEntity"
            >
            </CollapsableSelectionList>
          </v-col>
        </v-row>
      </v-col>
      <v-col cols="4">
        <ScannerAddMenu
          height="calc(80vh-1)"
          min-height="calc(80vh-1)"
          max-height="calc(80vh-1)"
          @add-item="addItem"
          ></ScannerAddMenu>  
        <BarPayment
          :bill="localBill"
          height="calc(80vh-1)"
          min-height="calc(80vh-1)"
          max-height="calc(80vh-1)"
          :bus="bus"
        ></BarPayment>
      </v-col>
    </v-row>

    <template v-slot:footer>
      <v-spacer></v-spacer>
      <v-btn text color="error" @click="close">Chiudi</v-btn>
    </template>
  </StandardDialog>
</template>

<script>
import CollapsableSelectionList from "@/components/common/CollapsableSelectionList.vue";

import ListItemComponent from "@/components/common/ListItemComponent.vue";
import DetailReservation from "../daily-reservation/DetailReservation.vue";
import StandardDialog from "@/components/common/StandardDialog";
import menuService from "@/services/bar/menu.service.js";
import billService from "@/services/bills/bills.service.js";
import BarPayment from "@/components/bar/daily-reservation/BarPayment.vue";
import ScannerAddMenu from "@/components/bar/daily-reservation/ScannerAddMenu.vue";
import Vue from "vue";

export default {
  name: "MenuDialog",
  components: {
    ListItemComponent,
    DetailReservation,
    StandardDialog,
    BarPayment,
    CollapsableSelectionList,
    ScannerAddMenu,
  },
  props: {
    providedItems: {
      type: Array,
      default: () => [],
    },
    bus: {
      type: Object,
      default: function () {
        return new Vue();
      },
    },
    bill: {},
    currentReservation: {
      type: Object,
      default: () => {},
    },
    openMenu: {
      type: Boolean,
      default: false,
    },
  },
  mounted() {
    this.fetchMenus();
    this.bus.$on("reload-items", this.reloadItems);
    this.fetchBillFromReservation();
  },
  data() {
    return {
      localProvidedItems: this.providedItems,
      localBill: this.bill,
      reservationSelected: this.currentReservation,
      localOpenMenu: this.openMenu,
      menus: undefined,
      reservationTitle: "Comanda",
    };
  },
  methods: {
    fetchBillFromReservation() {
      billService
        .getBillFromReservation(this.currentReservation)
        .then((bill) => {
          this.localProvidedItems = bill.billItems;
          this.localBill = bill;
          this.bus.$emit("reload-bill", this.localBill);
          this.bus.$emit("reload-items", this.localProvidedItems);
        })
        .catch(() => {});
    },
    reloadItems(items) {
      this.localProvidedItems = items;
    },
    fetchMenus() {
      menuService.list().then((menus) => {
        this.menus = menus;
      });
    },
    close() {
      this.localOpenMenu = false;
      this.$emit("close");
    },

    addItem(billItem) {
      let currentItem = billItem.item ? billItem.item : billItem;
      if (this.reservationSelected) {
        if (_.isEmpty(this.localBill)) {
          billService
            .createBillFromReservation(this.reservationSelected, [billItem])
            .then((results) => {
              this.localBill = results;
              this.loadReservationItem();
              this.$emit('reload-reservations')
            });
        } else {
          billService.addItemToBill(this.localBill, currentItem).then((_) => {
            this.loadReservationItem();
          });
        }
      }
    },
    removeItem(billItem) {
      let currentItem = billItem.item ? billItem.item : billItem;

      if (this.reservationSelected && !!this.localBill) {
        billService
          .removeItemFromBill(this.localBill, currentItem)
          .then((items) => {
            this.loadReservationItem();
          });
      }
    },
    deleteItem(billItem) {
      let currentItem = billItem.item ? billItem.item : billItem;

      if (this.reservationSelected && !!this.localBill) {
        billService
          .deleteItemFromBill(this.localBill, currentItem)
          .then((items) => {
            this.loadReservationItem();
          });
      }
    },
    loadReservationItem() {
      return new Promise((resolve, reject) => {
        if (this.reservationSelected && !!this.localBill) {
          billService
            .itemList(this.localBill)
            .then((billItems) => {
              this.localProvidedItems = billItems;
              this.bus.$emit("reload-items", billItems);
              this.bus.$emit("reload-bill", this.localBill);
              resolve(billItems);
            })
            .catch((err) => {
              console.log(err);
            });
        }
      });
    },
    handleSelectedMenuEntity(item) {
      this.addItem(item);
    },
  },
};
</script>

<style>
.over:hover {
  box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.2);
}
</style>