<template>
  <StandardDialog
    v-model="localOpenDialog"
    title="Invia sms a prenotazioni del"
    :dialog-height="null"
    dialog-max-width="90vw"
  >
    <div class="d-flex justify-center align-center" v-if="loadingSmsModels">
      <v-progress-circular indeterminate class="mt-2"></v-progress-circular>
    </div>
    <div v-else>
      <v-row>
        <v-col cols="6">
          <TypeDataTable
            v-model="reservationSelected"
            loading-text="Caricamento..."
            :disable-pagination="true"
            :show-select="true"
            :show-actions="false"
            :headers="headers"
            :items="itemsTable"
            :single-select="false"
            item-key="id"
          >
            <template v-slot:custom-reservationTime="{ item }">
              <v-chip color="primary">{{ item.startTimeString }}</v-chip>
            </template>
            <template v-slot:custom-reservationName="{ item }">
              <v-chip color="primary">{{ item.reservationName }}</v-chip>
            </template>
            <template v-slot:custom-reservationPhone="{ item }">
              <v-chip color="primary">{{ item.reservationPhone }}</v-chip>
            </template>
          </TypeDataTable>
        </v-col>
        <v-col cols="6">
          <CollapsableList
            :items="smsModelsAndCustomText"
            item-key="id"
            item-title="name"
          >
            <template v-slot:expansion="{ item }">
              <SmsTextVisualizer
                v-if="item.id != '_personalized'"
                :value="item.text"
              ></SmsTextVisualizer>
              <v-textarea
                filled
                hide-details="auto"
                label="Testo del messaggio"
                v-model="customTextMessage"
                v-else
                @click.stop.prevent
              ></v-textarea>
            </template>
            <template v-slot:extra-icons="{ item }">
              <v-btn
                v-if="item.id != '_personalized'"
                icon
                @click.stop.prevent="handleEditSmsModel(item)"
              >
                <v-icon medium>mdi-pencil</v-icon>
              </v-btn>
              <v-btn
                v-if="item.id != '_personalized'"
                icon
                class="ml-2"
                :loading="sendingSms"
                @click.stop.prevent="sendModel(item)"
              >
                <v-icon>mdi-arrow-right</v-icon>
              </v-btn>
              <v-btn
                v-else
                icon
                class="ml-2"
                :disabled="item.id == '_personalized' && !customTextMessage"
                @click.stop.prevent="sendCustomText"
              >
                <v-icon>mdi-arrow-right</v-icon>
              </v-btn>
            </template>
          </CollapsableList>
        </v-col>
      </v-row>
    </div>
    <template v-slot:footer-actions>
      <v-btn text color="red" @click="$emit('close')"> Annulla </v-btn>
    </template>

    <StandardDialog
      v-model="editSmsModelDialog"
      :title="editSmsModelDialogTitle"
      :dialog-height="null"
      persistent
      dialog-width="1000"
    >
      <div>
        <SmsModelForm
          v-model="editSmsModel"
          class="py-2"
          :valid.sync="editSmsModelValid"
          :hide-upload-image="true"
        ></SmsModelForm>
      </div>
      <template v-slot:footer>
        <v-spacer></v-spacer>
        <v-btn
          text
          color="error"
          @click="editSmsModelDialog = false"
          :disabled="loadingUpdate"
          >Annulla</v-btn
        >
        <v-btn
          text
          color="default"
          @click="updateSmsModel"
          :loading="loadingUpdate"
          :disabled="!editSmsModelValid"
          >Invia</v-btn
        >
      </template>
    </StandardDialog>
  </StandardDialog>
</template>

<script>
import smsModelsService from "@/services/smsModels/smsModels.service.js";
import smsService from "@/services/sms/sms.service.js";
import CollapsableList from "@/components/common/CollapsableList.vue";
import SmsTextVisualizer from "@/components/setting/sms/SmsTextVisualizer.vue";
import SmsModelForm from "@/components/setting/sms/SmsModelForm.vue";
import StandardDialog from "@/components/common/StandardDialog.vue";
import TypeDataTable from "@/components/common/TypeDataTable.vue";

export default {
  name: "ReservationListSendSmsDialog",
  components: {
    SmsTextVisualizer,
    CollapsableList,
    SmsModelForm,
    StandardDialog,
    TypeDataTable,
  },
  data: function () {
    return {
      smsModels: [],
      loadingSmsModels: false,
      customTextMessage: undefined,
      sendingSms: false,
      editSmsModelDialog: false,
      editSmsModelValid: false,
      editSmsModel: {},
      loadingUpdate: false,
      reservationSelected: [],
      itemsTable: [],
      customers: [],
      numbers: [],
      eventGroups: [],
      whatsappNumber: undefined,
      headers: [
        { text: "Ora Inizio", value: "reservationTime", type: "custom" },
        { text: "Nominativi", value: "reservationName", type: "custom" },
        {
          text: "Telefono Cellulare",
          value: "reservationPhone",
          type: "custom",
        },
      ],
    };
  },
  mounted: function () {
    this.fetchSmsModels();
  },
  props: {
    openDialog: {
      type: Boolean,
      default: false,
    },
    reservations: {
      type: Array,
      default: () => [],
    },
  },
  methods: {
    fetchSmsModels() {
      this.loadingSmsModels = true;
      smsModelsService.list().then((models) => {
        this.smsModels = models.rows;
        this.loadingSmsModels = false;
      });
    },
    sendModel(model) {
      if(!!this.customers) {
        this.sendingSms = true
        let params = {}
        if(!!model.values && model.values.length > 0) {
          for(let i = 0; i < model.values.length; i += 1) {
            for (let j = 0; j < this.customers.length; j += 1) {
              let substitutionText
              if(model.values[i].name == 'customer.firstname') {
                substitutionText = this.customers[j].name
              } else if(model.values[i].lastname == 'customer.lastname') {
                substitutionText = this.customers[j].name
              } else if(model.values[i].fullName == '<customer.fullname>') {
                substitutionText = `${this.customers[j].name}`
                model.text = model.text.replace('<customer.fullname>', substitutionText)
                params[model.values[i].fullName] = substitutionText
              } else if(model.values[i].appointment == '<customer.appointment>') {
                substitutionText = `${this.eventGroups[j].startDate.getHours()}:${this.eventGroups[j].startDate.getMinutes()}`
                model.text = model.text.replace('<customer.appointment>', substitutionText)
                params[model.values[i].appointment] = substitutionText
              }
              params[model.values[i].name] = substitutionText
            }
          }
        }

      return
      for (const number of this.numbers){
        number = number.replace("+","")
          smsService.sendWhatsappMessage( model.text, this.whatsappNumber, number).then((result) => {
          })
      }     
      this.sendingSms = false
      this.$delegates.snackbar("Messaggi Whatsapp inviati con successo");
      } else if(this.numbers.length === 0){
        this.$delegates.snackbar("Numero di telefono mancante");
      } else {
        this.$delegates.snackbar("Selezionare un cliente per procedere");
      }
      // smsModelsService.send(model, this.number, params).then(() => {
      //   this.$delegates.snackbar("Sms inviato con successo");
      //   this.sendingSms = false
      // })
    },
    sendCustomText() {
      this.sendingSms = true
      for (const number of this.numbers){
        number = number.replace("+","")
          smsService.sendWhatsappMessage( this.customTextMessage, this.whatsappNumber, number).then((result) => {
          })
      }     
      this.sendingSms = false
      this.$delegates.snackbar("Messaggi Whatsapp inviati con successo");
      this.customTextMessage = undefined 
    },
    handleEditSmsModel(smsModel) {
      this.editSmsModel = smsModel;
      this.editSmsModelDialog = true;
    },
    updateSmsModel() {
      this.loadingUpdate = true;
      smsModelsService.update(this.editSmsModel).then((models) => {
        this.fetchSmsModels();
        this.editSmsModel = {};
        this.loadingUpdate = false;
        this.editSmsModelDialog = false;
      });
    },
  },
  computed: {
    smsModelsAndCustomText() {
      return [
        ...this.smsModels,
        {
          id: "_personalized",
          name: "Testo Personalizzato",
        },
      ];
    },
    editSmsModelDialogTitle() {
      return !!this.editSmsModel && !!this.editSmsModel.name
        ? this.editSmsModel.name
        : "Nuovo modello";
    },
    localOpenDialog() {
      return this.openDialog;
    },
  },
  watch: {
    reservations(newVal) {
      this.itemsTable = [...newVal];
      for (const item of this.itemsTable) {
        item.startTimeString = new Date(item.startDate).toLocaleTimeString(
          "it-IT",
          {
            hour: "2-digit",
            minute: "2-digit",
          }
        );
       
      }
    },
    reservationSelected(newVal) {
      for (const item of newVal){
        let customer = {
          name: item.reservationName,
        }
        let eventGroup = {
          startDate: new Date(item.startDate)
        }
        let number = item.prefixCellphone + item.reservationPhone
        this.numbers.push(number)
        this.customers.push(customer)
        this.eventGroups.push(eventGroup)
      }
    }
  },
  settings: {
    whatsappNumber: {
      bind: 'whatsappNumber'
    },
  }
};
</script>

<style>
</style>