<template>
  <StandardDialog
    dialog-width="90vw"
    dialog-height="calc(90vh-1)"
    v-model="localOpenDailyZones"
    persistent
    title="Seleziona la prenotazione"
  >
    <div v-if="activeZones.length > 0">
      <div>
        <v-row>
          <v-col cols="3">
            <v-subheader
              class="d-flex justify-start align-center font-weight"
              style="font-size: 20px"
              >Zone del giorno
            </v-subheader>
          </v-col>
          <v-col>
            <v-card
              max-width="200"
              height="50"
              class="mt-3 d-flex flex-wrap justify-center align-center"
              :color="zoneSelected.zone.color"
            >
              <div
                width="100%"
                class="font-italic font-weight-normal"
                style="font-size: 20px"
              >
                {{ zoneSelected.zone.name }}
              </div>
            </v-card>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="2">
            <div class="ma-3 d-flex justify-center align-center">
              <ResponsiveCardSelector
                :items="activeZones"
                v-model="zoneSelected"
                :returnObject="true"
                item-key="key"
                card-max-height="40"
                card-min-height="40"
                card-max-width="120"
                card-min-width="120"
                :multiple="false"
              >
                <template v-slot:item="{ item, cardMinHeight }">
                  <div
                    class="d-flex flex-column justify-center align-center"
                    :style="{
                      'min-height':
                        cardMinHeight.indexOf('px') != -1
                          ? cardMinHeight
                          : cardMinHeight + 'px',
                      'background-color': item.zone.color,
                    }"
                  >
                    <div class="font-weight-medium">
                      {{ item.zone.name }}
                    </div>
                  </div>
                </template>
              </ResponsiveCardSelector>
            </div>
          </v-col>

          <v-col class="mt-4" cols="10">
            <ZoneMap
              :zone-selected="zoneSelected"
              @handle-selection-element="handleSelectionElement"
            ></ZoneMap>
          </v-col>
        </v-row>
      </div>
    </div>
    <div v-else class="d-flex justify-center align-center">
      Nessuna zona attiva oggi
    </div>

    <ReservationSelectDialog
      dialog-width="360"
      dialog-height="calc(360px-10px)"
      persistent
      v-model="openReservationSelectDialog"
      :title="titleReservationSelectDialog"
    >
      <v-subheader class="d-flex jutify-center align-center">
        Associa la pronotazione al tavolo
      </v-subheader>

      <v-list flat>
        <template v-for="(reservation, i) in reservations">
          <v-list-item-group :key="i" color="primary">
            <v-list-item
              :disabled="statusClosed(reservation)"
              @click="changeCurrentReservation(reservation)"
              :key="i"
            >
              <v-list-item-title>
                <div
                  :style="{
                    'text-decoration': statusClosed(reservation)
                      ? 'line-through'
                      : '',
                  }"
                >
                  {{ reservation.reservationName }}
                </div>
                <DateTimeFormattor
                  :with-time="true"
                  v-model="reservation.startDate"
                  format="dd/mm/yyyy"
                ></DateTimeFormattor>
              </v-list-item-title>
            </v-list-item>
          </v-list-item-group>
        </template>
      </v-list>
    </ReservationSelectDialog>

    <template v-slot:footer>
      <v-spacer></v-spacer>
      <v-btn text color="error" @click="close">Chiudi</v-btn>
    </template>
  </StandardDialog>
</template>

<script>
import StandardDialog from "@/components/common/StandardDialog";
import ReservationSelectDialog from "@/components/common/StandardDialog";
import ResponsiveCardSelector from "@/components/common/ResponsiveCardSelector";
import DateTimeFormattor from "@/components/common/DateTimeFormattor.vue";

import zoneActiveHandler from "@/components/bar/handlers/zoneActiveHandler.js";
import tableService from "@/services/bar/table.service";

import ZoneMap from "@/components/bar/reservation/detail-zone/ZoneMap.vue";

export default {
  name: "DailyZonesDialog",
  components: {
    DateTimeFormattor,

    StandardDialog,
    ResponsiveCardSelector,
    ZoneMap,
    ReservationSelectDialog,
  },
  data() {
    return {
      reservations: undefined,
      localOpenDailyZones: this.openDailyZones,
      reservationSelected: undefined,
      currentTableSelected: undefined,
      openReservationSelectDialog: false,
      activeZones: [],
      zoneSelected: undefined,
      weekEnablings: undefined,
      titleReservationSelectDialog: "",
    };
  },
  props: {
    openDailyZones: {
      type: Boolean,
      default: false,
    },
  },
  mounted() {
    this.fetchZones();
  },
  methods: {
    statusClosed(reservation) {
      if (!!reservation.billReservation)
        return reservation.billReservation.bill.status == "closed";
    },
    fetchZones() {
      this.weekEnablings = new Date().getDay();
      zoneActiveHandler
        .loadActiveZones(this.weekEnablings)
        .then((activeZones) => {
          this.activeZones = activeZones;
          this.zoneSelected = activeZones[0];
        });
    },
    handleSelectionElement(table) {
      this.currentTableSelected = table;

      if (!!table) {
        this.tableSelected = table;
        zoneActiveHandler
          .loadReservations({
            tableId: table.id,
            todayReservation: true,
          })
          .then((reservations) => {
            // TODO for reload reservations
            this.reservations = reservations;
            zoneActiveHandler.on("reload-reservations", ({ reservations }) => {
              this.reservations = reservations;
            });
            this.titleReservationSelectDialog =
              "Lista Prenotazioni del tavolo " + table.i;
            this.openReservationSelectDialog = true;
          });
      }
    },
    changeCurrentReservation(currentReservation) {
      let tableWithReservation = {
        id: this.currentTableSelected.id,
        currentReservationId: currentReservation.id,
      };

      tableService.update(tableWithReservation).then((value) => {
        this.openReservationSelectDialog = false;
      });

      //create bill_reservations
      this.reservationSelected = currentReservation;
      this.$emit("change-reservation", currentReservation);
      this.openReservationSelectDialog = false;
      this.close();
    },
    close() {
      this.localOpenMenu = false;
      this.$emit("close");
    },
  },
  watch: {
    zoneSelected(newVal) {
      this.zoneSelected = newVal;
    },
  },
};
</script>

<style>
</style>