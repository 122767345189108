var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('div',{staticClass:"d-flex flex-column justify-center align-center"},[_c('ResponsiveCardSelector',{attrs:{"items":_vm.zones,"returnObject":true,"item-key":"name","card-max-height":"20","card-min-height":"20","card-max-width":"120","card-min-width":"120","apply-d-flex":false,"mandatory":true},scopedSlots:_vm._u([{key:"item",fn:function({ item, cardMinHeight }){return [_c('div',{staticClass:"d-flex flex-column justify-center align-center",style:({
            'min-height':
              cardMinHeight.indexOf('px') != -1
                ? cardMinHeight
                : cardMinHeight + 'px',
            'background-color': item.color,
          })},[_c('div',{staticClass:"font-weight-medium"},[_vm._v(" "+_vm._s(item.name)+" ")])])]}}]),model:{value:(_vm.selectedZone),callback:function ($$v) {_vm.selectedZone=$$v},expression:"selectedZone"}})],1),_vm._l((_vm.zones),function(zone){return _c('div',{key:zone.id},[(zone.name == _vm.selectedComponent)?_c('CategoriesCalendar',{staticClass:"section-fade-in",staticStyle:{"height":"86vh"},attrs:{"interval-height-default":8,"color":zone.color,"category-label":"Tavoli","category-placeholder":"Tutti i tavoli","category-text-key":"code","category-sub-text-key":"guests","category-sub-text":"ospiti","category-value-key":"id","categories-fetcher":_vm.fetchZoneTables,"canManage":true,"bus":_vm.bus,"event-category":_vm.categoryFetcher,"event-category-setter":_vm.categorySetter,"event-end":"endDate","event-start":"startDate","event-color":_vm.colorFetcher,"event-name":_vm.nameFetcher,"move-between-category":!_vm.disabled,"move-in-time":!_vm.disabled,"event-timed":(reservation) => {
          return !reservation.allDay;
        },"events-fetcher":_vm.fetchReservationsForCategories,"category-width":100,"selectMaxWidth":"200px"},on:{"new-event":_vm.handleNewReservation,"show-event":_vm.handleShowReservation,"end-drag":_vm.handleEndDrag},scopedSlots:_vm._u([{key:"toolbar-append",fn:function(){return [_c('v-btn',{attrs:{"fab":"","text":"","color":"grey darken-2"},on:{"click":function($event){_vm.openSendSmsDialog = true}}},[_c('v-icon',[_vm._v("mdi-message-text")])],1)]},proxy:true}],null,true)}):_vm._e()],1)}),(_vm.openReservationDialog)?_c('ReservationCreateDialog',{attrs:{"new-reservation":_vm.newReservation,"reservations":_vm.reservations,"open-dialog":_vm.openReservationDialog,"table-selected":_vm.tableSelected,"reservation-day":_vm.weekEnablings},on:{"saved-reservation":_vm.handleSaveReservation,"updated-reservation":_vm.handleUpdateReservation,"delete-reservation":_vm.closeReservation,"close":_vm.closeReservation,"open-menu":_vm.openMenuFromReservation}}):_vm._e(),(_vm.openMenu)?_c('MenuDialogCalendar',{staticStyle:{"overflow-y":"hidden"},attrs:{"bus":_vm.bus,"open-menu":_vm.openMenu,"current-reservation":_vm.currentReservation},on:{"close":_vm.closeMenu}}):_vm._e(),_c('ReservationListSendSmsDialog',{attrs:{"openDialog":_vm.openSendSmsDialog,"reservations":_vm.reservations},on:{"close":function($event){_vm.openSendSmsDialog = false}}})],2)
}
var staticRenderFns = []

export { render, staticRenderFns }