<template>
  <div>
    <v-card min-height="87vh" height="87vh">
      <div>
        <div class="threeGroup mt-1" >
          <v-btn
            style="cursor: pointer"
            @click="openMap"
          >
            Mappa
            <v-icon>mdi-select-group</v-icon>
          </v-btn>
          <v-btn
            @click="createReservation"
            style="cursor: pointer"
          >
          Crea
            <v-icon>mdi-book-plus</v-icon>
          </v-btn>
          <v-btn
            @click="openReservation"
            style="cursor: pointer"
          >
            Apri
            <v-icon class="ml-1">mdi-book-open</v-icon>
          </v-btn>
        </div>
      </div>
      <v-card-title
        class="d-flex justify-center align-center font-weight"
        style="font-size: 23px"
      >
        <!-- <v-icon @click="openReservation" class="mr-3">mdi-book-plus</v-icon> -->
        {{ custumTitle }}
      </v-card-title>
      <v-card-subtitle class="d-flex justify-center align-center font-weight">
        {{ custumSubTitle }}
      </v-card-subtitle>

      <ListItemComponent
        :flat="true"
        class="ma-2"
        title="Prodotti Ordinati"
        color=" light-green lighten-5"
        :items="localProvidedItems"
        :item-description="(item) => !!item.serviceId ? item.service.name : item.item.description"
        :item-price="(item) => !!item.serviceId ? item.service.price : item.item.priceSelling"
        :item-quantity="(item) => item.quantity"
        min-height="69vh"
        max-height="62vh"
        :show-actions="true"
        v-if="!checkReservation"
        @add-item="addItem"
        @remove-item="removeItem"
        @delete-item="deleteItem"
      >
      </ListItemComponent>
    </v-card>

    <DailyZonesDialog
      v-if="openDailyZones"
      :open-daily-zones="openDailyZones"
      @change-reservation="handleChangeReservation"
      @close="openDailyZones = false"
      style="overflow-y: hidden"
    >
    </DailyZonesDialog>
    <MenuDialog
      :bus="bus"
      :open-menu="openMenu"
      @close="closeMenu"
      style="overflow-y: hidden"
      :current-reservation="localReservation"
      :bill="localBill"
      :provided-items.sync="localProvidedItems"
    >
    </MenuDialog>
    <StandardDialog
      v-model="newQuickReservationDialog"
      :dialog-height="null"
      title="Nuova Comanda Al Banco"
      dialog-width="50vw"
    >
      <v-row>
        <v-col>
          <v-text-field
            v-model="nameQuickReservation"
            filled
            dense
            label="Nome Prenotazione"
            prepend-icon="mdi-account"
            hide-details="auto"
            class="mt-2"
          ></v-text-field>
        </v-col>
      </v-row>
      <v-row>
        <v-col>
        <DatePicker
          :disabled="true"
          v-model="dateQuickReservation" 
          label="Data prenotazione" 
          hide-details="auto"
          filled
        ></DatePicker>
        </v-col>
      </v-row>
    <v-row>
      <v-col cols="12">
        <v-textarea v-model="notesQuickReservation" color="primary" filled label="Note">
        </v-textarea>
      </v-col>
    </v-row>
     <template v-slot:footer-actions>
        <v-btn text color="error" @click="newQuickReservationDialog = false">Chiudi</v-btn>
        <v-btn text color="black" @click="saveQuickReservation">Salva</v-btn>
      </template>
    </StandardDialog>
  </div>
</template>

<script>
import _ from "lodash";
import Vue from "vue";
import DailyZonesDialog from "@/components/bar/daily-reservation/DailyZonesDialog.vue";
import MenuDialog from "@/components/bar/daily-reservation/MenuDialog.vue";
import ListItemComponent from "@/components/common/ListItemComponent.vue";
import billService from "@/services/bills/bills.service/";
import StandardDialog from "@/components/common/StandardDialog.vue"
import DatePicker from '@/components/common/DatePickerCommon.vue'
import reservationService from "@/services/bar/reservation.service";

export default {
  name: "CurrentReservation",
  components: {
    DailyZonesDialog,
    MenuDialog,
    ListItemComponent,
    StandardDialog,
    DatePicker,
  },
  props: {
    bus: {
      type: Object,
      default: function () {
        return new Vue();
      },
    },
    reservation: {
      type: Object,
      default: function () {
        return {};
      },
    },
    bill: {},
    scanning: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      openMenu: false,
      openDailyZones: false,
      custumTitle: "",
      custumSubTitle: "",
      localProvidedItems: [],
      localReservation: this.reservation,
      localBill: this.bill,
      newQuickReservationDialog: false,
      notesQuickReservation: '',
      nameQuickReservation: "Cliente Al Banco",
      dateQuickReservation: new Date(),
      quickrReservation: undefined,
    };
  },
  computed: {
    checkReservation() {
      return _.isEmpty(this.localReservation);
    },
    reservationSelected() {
      return (
        (this.localReservation &&
          Object.keys(this.localReservation).length !== 0) ||
        this.localReservation.constructor !== Object
      );
    },
  },
  mounted() {
    let self = this;
    this.bus.$on("selection:reservation", self.handleChangeReservation);
    this.bus.$on("add-item", self.addItem);
    this.bus.$on("remove-item", self.removeItem);
    this.bus.$on("delete-item", self.deleteItem);
    this.bus.$on("reload-reservation", self.reloadReservations)
    this.bus.$on("new-reservation", self.reloadReservations)
  },
  methods: {
    addItem(billEntity) {
      if(!billEntity)
        return
      if (this.reservationSelected) {
        if (_.isEmpty(this.localBill)) {
          if(!!billEntity.itemTypeId) {
            billService
              .createBillFromReservation(this.localReservation, [billEntity])
              .then((results) => {
                this.localBill = results;
                this.loadReservationItem();
                this.$emit('reload-reservations')

              }).catch((err)=>{console.log(err)});
          } else if(!!billEntity.serviceTypeId){
             billService
              .createBillFromReservation(this.localReservation, [billEntity])
              .then((results) => {
                this.localBill = results;
                this.loadReservationItem();
                this.$emit('reload-reservations')

              }).catch((err)=>{console.log(err)});
          }
        } else {
          if(!!billEntity.itemTypeId){
            billService.addItemToBill(this.localBill, billEntity).then(_ => {
              this.loadReservationItem()
            })
          } else if(!!billEntity.item){
            billService.addItemToBill(this.localBill,billEntity.item).then(_ => {
              this.loadReservationItem()
            })        
          } else if(!!billEntity.serviceTypeId){
            billService.addServiceToBill(this.localBill,billEntity).then(_ => {
              this.loadReservationItem()
            })        
          } else if(!!billEntity.service){
            billService.addServiceToBill(this.localBill,billEntity.service).then(_ => {
              this.loadReservationItem()
            })
          }  
        }
      }
    },
    reloadReservations() {
      this.$emit('reload-reservations')
    },
    removeItem(billItem) {
      let currentItem = !!billItem.item ? billItem.item : billItem.service

      if (this.reservationSelected && !!this.localBill) {
        if(!!billItem.itemId){
        billService
          .removeItemFromBill(this.localBill, currentItem)
          .then(() => {
            this.loadReservationItem();
          })
        }
        else if(!!billItem.serviceId){
          billService
            .removeServiceFromBill(this.localBill, currentItem)
            .then(() => {
              this.loadReservationItem()
            })
        }
      }
    },
    deleteItem(billItem) {
      let currentItem = !!billItem.item ? billItem.item : billItem.service

      if (this.reservationSelected && !!this.localBill) {
        if(!!billItem.itemId){
        billService
          .deleteItemFromBill(this.localBill, currentItem)
          .then((items) => {
            this.loadReservationItem();
          })
        }
        else if(!!billItem.serviceId){
        billService
          .deleteServiceFromBill(this.localBill, currentItem)
          .then((items) => {
            this.loadReservationItem();
          })
        }
      }
    },
    loadReservationItem() {
      return new Promise((resolve, reject) => {
        if (this.reservationSelected && !!this.localBill) {
          billService
            .entitiesList(this.localBill)
            .then( billEntities => {
              this.localProvidedItems = billEntities
              this.bus.$emit("reload-items", billEntities)
              resolve(billEntities)
            })
            .catch((err) => {
              console.log(err)
            });
        } else if (!this.localBill) {
          this.bus.$emit("reload-bill");
          this.localProvidedItems = [];
        }
      });
    },
    handleChangeReservation(reservation) {
      this.reloadBill(reservation).then((value) => {
        this.loadReservationItem();
      });
    },
    reloadBill(reservation) {
      return new Promise((resolve, reject) => {
        billService
          .list(1, 500, { type: "item", reservation: reservation })
          .then((response) => {
            this.localReservation = reservation;
            this.localBill = response.rows[0];
            this.bus.$emit("reload-bill", this.localBill);
            this.generateCustomTitle();
            resolve(response.rows);
          })
          .catch((error) => {
            reject(error);
          });
      });
    },
    generateCustomTitle() {
      let guests =
        this.localReservation.guests <= 1
          ? this.localReservation.guests
          : this.localReservation.guests - 1;

      let guestLable = guests > 1 ? " ospiti" : " ospite";

      if (guests >= 1 && this.localReservation.guests >= 2)
        this.custumTitle = this.localReservation
          ? this.localReservation.reservationName +
            " con " +
            guests +
            guestLable
          : "";
      else this.custumTitle = this.localReservation.reservationName;

      if(!!this.localReservation.tableCode && !!this.localReservation.zone)
        this.custumSubTitle =
          "Tavolo " +
          this.localReservation.tableCode +
          " in " +
          this.localReservation.zone.name;
      else
        this.custumSubTitle = ''    
    },
    closeMenu() {
      this.openMenu = false;
      this.loadReservationItem();
    },

    openMap() {
      this.openDailyZones = true;
    },
    openReservation() {
      this.openMenu = true;
    },
    createReservation() {
      this.localReservation = {}
      this.localBill = {}
      this.localProvidedItems = []
      this.newQuickReservationDialog = true;
    },
    async saveQuickReservation() {
      try {
        this._buildQuickReservation()
        await reservationService.create(this.quickReservation)
        this.reloadReservations()
      } catch (error) {
        console.log(error)
      }
      this.newQuickReservationDialog = false
    },
    _buildQuickReservation(){

      let endQuickDate = new Date();
      endQuickDate.setHours(endQuickDate.getHours() + 2);

      this.quickReservation = {
        tableId: undefined,
        zoneId: undefined,
        reservationName: this.nameQuickReservation,
        reservationPhone: undefined,
        notes: this.notesQuickReservation,
        guests: 1,
        tableCode: undefined,
        reservationDate: new Date(),
        startDate: new Date(),
        endDate: endQuickDate,
        createdAt: new Date(),
      }
    }
  },
};
</script>

<style>
.threeGroup {
  display: flex;              
  flex-direction: row;           
  flex-wrap: nowrap;             
  justify-content: space-between; 
  padding: 10px;
}
</style>