<template>
  <div>
    <v-row class="ma-2">
      <v-text-field
        class="ma-2"
        max-width="5vw"
        dense
        filled
        v-model="reservationName"
        :rules="[presenceRule]"
        label="Nome"
      ></v-text-field>
      <v-text-field
        class="ma-2"
        type="number"
        max-width="5vw"
        dense
        filled
        v-model="reservationPeopleNumber"
        :rules="[presenceRule]"
        label="Numero di persone"
      ></v-text-field>
    </v-row>
    <v-row class="ma-2">
      <v-text-field
        class="ma-2"
        v-model="prefixCellphone"
        label="Prefisso"
        dense
        filled
      ></v-text-field>
      <v-text-field
        class="ma-2"
        max-width="5vw"
        dense
        filled
        v-model="reservationPhone"
        :rules="[presenceRule, onlyDigits]"
        label="Telefono"
      ></v-text-field>
    </v-row>
    <v-row>
      <v-col cols="4" class="">
        <DatePicker
          v-model="localReservationDate"
          label="Data Prenotazione"
        >
        </DatePicker>
      </v-col>
      <!--<v-col cols="4">
        <v-select
          v-model="dayPeriod"
          label="Quando"
          :items="[{text:'Pranzo',key:'lunch'},{text:'Aperitivo',key:'appetizer'},{text:'Cena',key:'dinner'}]"
          item-text="text"
          item-value="key"
          dense
          filled
          hide-details="auto"
          @input="clickDayPeriod"
          :rules="[presenceRule]"
        ></v-select>  
      </v-col>-->
    </v-row>
    <v-row>
      <v-col>
        Dalle ore
        <BetterTimePicker
          :label="null"
          :value="localStartDate"
          :handle-time-change="handleTimeStart"
        ></BetterTimePicker>
      </v-col>
      <v-col>
        alle ore
        <BetterTimePicker
          :label="null"
          :value="localEndDate"
          :handle-time-change="handleTimeEnd"
        ></BetterTimePicker>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12">
        <v-textarea v-model="localNotes" color="primary" filled label="Notes">
        </v-textarea>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import StandardDialog from "@/components/common/StandardDialog.vue";
import ErrorDialog from "@/components/common/StandardDialog.vue";
import DatePicker from '@/components/common/DatePickerCommon.vue'
import DateTimePickerCircular from '@/components/common/DateTimePickerCircular.vue';
import BetterTimePicker from '@/components/common/BetterTimePicker.vue'
import dateUtils from '@/mixins/common/dateUtils'



import zoneActiveHandler from "@/components/bar/handlers/zoneActiveHandler.js";
import reservationService from "@/services/bar/reservation.service.js";
import Vue from "vue";
import PaymentTermsNewFormVue from "../../areas/payments/paymentTerms/PaymentTermsNewForm.vue";

export default {
  name: "ReservationForm",
  components: {
    StandardDialog,
    ErrorDialog,
    DatePicker,
    BetterTimePicker
  },
  props: {
    tableSelected: {
      type: Object,
      default: undefined,
    },
    reservationSelected: {
      type: Object,
      default: undefined,
    },
    valid: {
      type: Boolean,
      default: false,
    },
    dayPeriodProps: {
      type: String,
      default: undefined
    },
    bus: {
      type: Object,
      default: function () {
        return new Vue();
      },
    },
  },
  mixins: [
    dateUtils
  ],
  data: function () {
    return {
      errorMessage: "",
      reservationName: "",
      reservationPeopleNumber: this.tableSelected.guests,
      localNotes: "",
      startDate: undefined,
      endDate: undefined,
      localStartDate: undefined,
      localEndDate: undefined,
      localReservationDate: undefined,
      showErrorDialog: false,
      localTableSelected: undefined,
      localZoneSelected: undefined,
      localReservations: [],
      localReservationSelected: undefined,
      reservationPhone: "",
      dayPeriod: undefined,
      prefixCellphone: undefined,
      onlyDigits: (v) => {
        const pattern = /^\d{8}\d*$/
        return pattern.test(v) || 'Numero non valido'
      },
      presenceRule: (v) => {
        return !!v || 'Il campo è obbligatorio'
      },
    };
  },
  computed: {
    canSave() {
      return (
        this.reservationName &&
        this.reservationPeopleNumber > 0 &&
        this.localStartDate !== undefined &&
        this.reservationPhone && 
        !!this.localStartDate &&
        !!this.localEndDate
      ) 
    },
  },
  mounted() {
    this.localTableSelected = this.tableSelected;
    if (this.tableSelected.zone)
      this.localZoneSelected = this.tableSelected.zone;
    else throw new Error("No zone provided");

    if (this.reservationSelected) {
      this.localReservationSelected = this.reservationSelected;
      this.setLocalReservation(this.localReservationSelected);
    }

    if(!!this.dayPeriodProps)
      this.dayPeriod = this.dayPeriodProps

    this.$emit("update:valid", this.canSave);

    this.bus.$on("save-reservation", () => {
      this.saveReservation();
    });
  },
  methods: {
    close() {
      this.$emit("close");
    },
    handleTimeStart(time) {
      this.localStartDate = time
    },
    handleTimeEnd(time) {
      this.localEndDate = time
    },
    setLocalReservation(reservation) {
      let startDate;
      let endDate;
      let tzoffset = (new Date()).getTimezoneOffset() * 60000;

      reservation.endDate = reservation.endDate ? reservation.endDate : this.addHours(reservation.startDate, 2)
      

      if (reservation.startDate) {
        let time = new Date(new Date(reservation.startDate) - tzoffset )
          .toISOString()
          .substring(11, 16)
          .split(":");
        startDate = Number(time[0]) + ":" + time[1];
      }

      if (reservation.endDate) {
        let time = new Date(new Date(reservation.endDate) - tzoffset)
          .toISOString()
          .substring(11, 16)
          .split(":");
        endDate = Number(time[0]) + ":" + time[1];
      }
      this.reservationName = reservation.reservationName;
      this.reservationPhone = reservation.reservationPhone;
      this.reservationPeopleNumber =
        reservation.guests || this.tableSelected.guests;
      this.localStartDate = reservation.startDate ? startDate : "";
      this.localEndDate = reservation.endDate ? endDate : "";
      this.localReservationDate = new Date(reservation.reservationDate);
      this.dayPeriod = reservation.dayPeriod
      this.localNotes = reservation.notes;
      !!reservation.prefixCellphone ? this.prefixCellphone = reservation.prefixCellphone : this.prefixCellphone = '+39'
    },
    saveReservation() {
      this._modifyDate();
      let reservationDateCorrect = this._checkTimeReservation();
      if (!!reservationDateCorrect) {
        if (!!this.localReservationSelected && !!this.localReservationSelected.id) {
          let reservation = this._buildReservationUpdateObject();
          reservationService
            .update({
              reservation: reservation,
            })
            .then(() => {
              this.$emit("updated-reservation");
            })
            .catch((err) => {
              this.errorMessage = "Errore nella creazione della prenotazione";
              this.showErrorDialog = true;
              console.log(err);
            });
        } else {
          let reservation = this._buildReservationObject();
          reservationService
            .create(reservation)
            .then(() => {
              this.$emit("saved-reservation");
            })
            .catch((err) => {
              this.errorMessage = "Errore nella creazione della prenotazione";
              this.showErrorDialog = true;
              console.log(err);
            });
        }
      } else {
        alert("Prenotazione già presente nella fascia oraria selezionata");
      }
    },
    _modifyDate() {
      let tempReservationDate = new Date(this.localReservationDate);

      let timeStart = this.localStartDate.split(":");
      this.startDate = new Date(
        tempReservationDate.setHours(timeStart[0], timeStart[1])
      );

      let timeEnd = this.localEndDate.split(":");
      this.endDate = new Date(
        tempReservationDate.setHours(timeEnd[0], timeEnd[1])
      );

      this.localReservationDate = new Date(this.startDate);
    },
    _checkTimeReservation() {
      let isRight = true;
      let localRSDate;
      let localREDate;

      for (let i = 0; i < zoneActiveHandler.reservationsList.length; i++) {
        const reservation = zoneActiveHandler.reservationsList[i];
        localRSDate = new Date(reservation.startDate);
        localREDate = new Date(reservation.endDate);

        if (this.startDate.getTime() == localRSDate.getTime()) {
          isRight = false;
          break;
        }
        if (
          this.startDate.getTime() > localRSDate.getTime() &&
          this.startDate.getTime() <= localREDate.getTime()
        ) {
          isRight = false;
          break;
        }

        if (
          this.startDate.getTime() < localRSDate.getTime() &&
          this.endDate.getTime() > localRSDate.getTime() &&
          this.endDate.getTime() <= localREDate.getTime()
        ) {
          isRight = false;
          break;
        }
      }
      return isRight
    },
    _buildReservationObject() {
        return {
          tableId: this.localTableSelected.id,
          zoneId: this.localZoneSelected.id,
          reservationName: this.reservationName,
          reservationPhone: this.reservationPhone,
          prefixCellphone: this.prefixCellphone,
          dayPeriod: this.dayPeriod,
          notes: this.localNotes,
          guests: this.reservationPeopleNumber,
          tableCode: this.localTableSelected.i || this.localTableSelected.code,
          reservationDate: this.localReservationDate,
          startDate: this.startDate,
          endDate: this.endDate,
          createdAt: new Date(),
        }
    },
    _buildReservationUpdateObject() {
      return {
        id: this.reservationSelected.id,
        tableId: this.localTableSelected.id,
        zoneId: this.localZoneSelected.id,
        reservationName: this.reservationName,
        reservationPhone: this.reservationPhone,
        prefixCellphone: this.prefixCellphone,
        dayPeriod: this.dayPeriod,
        notes: this.localNotes,
        guests: this.reservationPeopleNumber,
        tableCode: this.localTableSelected.i,
        reservationDate: this.localReservationDate,
        startDate: this.startDate,
        endDate: this.endDate,
        createdAt: new Date(),
      };
    },
    clickDayPeriod() {
      if(this.dayPeriod == 'lunch') {
        this.localStartDate = '12:00'
        this.localEndDate = '14:00'
      }
      else if(this.dayPeriod == 'appetizer')  {
        this.localStartDate = '17:00'
        this.localEndDate = '19:00'
      }
      else if(this.dayPeriod == 'dinner') {
        this.localStartDate = '20:00'
        this.localEndDate = '22:00'
      }
      
    }
  },
  watch: {
    reservationSelected(newVal) {
      if (newVal) {
        this.localReservationSelected = newVal;
        this.setLocalReservation(this.localReservationSelected);
      }
    },
    canSave(newVal) {
      this.$emit("update:valid", newVal);
    },
  },
};
</script>