var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('v-card',{attrs:{"min-height":"calc(90vh-1)","max-height":"calc(90vh-1)"}},[_c('CollapsableList',{attrs:{"items":_vm.localItems,"item-key":"id","item-title":_vm.itemTitle,"item-subtitle":_vm.itemSubTitle,"click-mode":"expandOnly"},scopedSlots:_vm._u([{key:"expansion",fn:function({ item: container }){return [_c('div',{staticClass:"ma-2",staticStyle:{"font-size":"20px"},attrs:{"flat":""}},[_c('v-btn',{attrs:{"color":container.color,"icon":""}},[_c('v-icon',[_vm._v(" mdi-clipboard")])],1)],1),_c('ResponsiveCardSelector',{staticClass:"d-flex justify-center align-center",attrs:{"name":"MenuItemContainer","items":container.items,"returnObject":true,"item-key":"id","card-max-height":"20","card-min-height":"20","card-max-width":"180","card-min-width":"180","multiple":false},on:{"click":function($event){return _vm.handleSelectedMenuItem($event)}},scopedSlots:_vm._u([{key:"item",fn:function({ item, cardMinHeight }){return [_c('div',{staticClass:"over d-flex flex-column justify-center align-center",style:({
                'min-height':
                  cardMinHeight.indexOf('px') != -1
                    ? cardMinHeight
                    : cardMinHeight + 'px',
                'background-color': container.color,
              })},[_c('div',{staticClass:"ma-2 font-weight-medium"},[_vm._v(" "+_vm._s(item.description)+" ")]),_c('div',{staticClass:"font-weight-medium"},[_vm._v("€ "+_vm._s(item.priceSelling))])])]}}],null,true),model:{value:(_vm.selectedMenuItem),callback:function ($$v) {_vm.selectedMenuItem=$$v},expression:"selectedMenuItem"}})]}}])})],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }