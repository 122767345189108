<template>
  <div>
    <div class="d-flex flex-column justify-center align-center">
      <ResponsiveCardSelector
        :items="zones"
        v-model="selectedZone"
        :returnObject="true"
        item-key="name"
        card-max-height="20"
        card-min-height="20"
        card-max-width="120"
        card-min-width="120"
        @input="handleChangeZone"
      >
        <template v-slot:item="{ item, cardMinHeight }">
          <div
            class="d-flex flex-column justify-center align-center"
            :style="{
              'min-height':
                cardMinHeight.indexOf('px') != -1
                  ? cardMinHeight
                  : cardMinHeight + 'px',
              'background-color': item.color,
            }"
          >
            <div class="font-weight-medium">
              {{ item.name }}
            </div>
          </div>
        </template>
      </ResponsiveCardSelector>
    </div>
    <v-toolbar flat height="fit-content">
      <v-btn outlined class="mr-4" color="grey darken-2" @click="setToday"
        >Oggi</v-btn
      >
      <v-btn fab text small color="grey darken-2" @click="prev">
        <v-icon small>mdi-chevron-left</v-icon>
      </v-btn>
      <v-btn fab text small color="grey darken-2" @click="next">
        <v-icon small>mdi-chevron-right</v-icon>
      </v-btn>
      <v-toolbar-title>{{ dateToShow }}</v-toolbar-title>
      <v-menu offset-y v-if="openCalendar">
        <template v-slot:activator="{ attrs, on }">
          <v-btn icon v-on="on" v-bind="attrs" class="ml-2">
            <v-icon>mdi-calendar-cursor</v-icon>
          </v-btn>
        </template>
        <v-date-picker
          locale="it"
          v-model="focus"
          @input="changeDateFromDatePicker" 
          first-day-of-week="1"
        ></v-date-picker>
      </v-menu>
      <v-btn fab text color="grey darken-2" @click="openSendSmsDialog = true">
        <v-icon >mdi-message-text</v-icon>
      </v-btn>
      <v-btn icon @click="$emit('tableView')">
        <v-icon>mdi-cached</v-icon>
      </v-btn>
    </v-toolbar>
    <TypeDataTable
      :headers="headers"
      :disable-pagination="true"
      :items="reservations"
      :show-select="false"
      :show-actions="false"
      class="pa1"
    >
      <template v-slot:custom-name="{ item }">
        <v-chip v-if="!!item['code']" :color="selectedZone.color">
          {{ item["code"] }}</v-chip
        >
      </template>
      <template v-slot:custom-lunch="{ item }">
        <v-chip
          v-if="!!item.lunch"
          color="#B4E1FF"
          @click="openEditDialog(item.lunch, item.code, item.tableId, 'lunch')"
        >
          {{
            item.lunch.reservationName +
            " (x" +
            item.lunch.guests +
            ") " +
            item.lunch.startTimeString +
            "/" +
            item.lunch.lastTimeString
          }}</v-chip
        >
        <div
          v-else
          class="clickableEmptyCell"
          @click.stop="openCreateDialog(item, 'lunch')"
        ></div>
      </template>
      <template v-slot:custom-appetizer="{ item }">
        <v-chip
          v-if="!!item.appetizer"
          color="#feb236"
          @click="
            openEditDialog(item.appetizer, item.code, item.tableId, 'appetizer')
          "
        >
          {{
            item.appetizer.reservationName +
            " (x" +
            item.appetizer.guests +
            ") " +
            item.appetizer.startTimeString +
            "/" +
            item.appetizer.lastTimeString
          }}</v-chip
        >
        <div
          v-else
          class="clickableEmptyCell"
          @click.stop="openCreateDialog(item, 'appetizer')"
        ></div>
      </template>
      <template v-slot:custom-dinner="{ item }">
        <v-chip
          v-if="!!item.dinner"
          color="#E94757"
          @click="
            openEditDialog(item.dinner, item.code, item.tableId, 'dinner')
          "
        >
          {{
            item.dinner.reservationName +
            " (x" +
            item.dinner.guests +
            ") " +
            item.dinner.startTimeString +
            "/" +
            item.dinner.lastTimeString
          }}</v-chip
        >
        <div
          v-else
          class="clickableEmptyCell"
          @click.stop="openCreateDialog(item, 'dinner')"
        ></div>
      </template>
    </TypeDataTable>

    <ReservationCreateDialog
      v-if="openReservationCreateDialog"
      :dayPeriodProps="dayPeriodReservationCreateDialog"
      :new-reservation="reservationCreateDialog"
      :open-dialog="openReservationCreateDialog"
      :table-selected="tableSelectedCreateDialog"
      @close="openReservationCreateDialog = false"
      @saved-reservation="handleReservationCreatedOrUpdated"
      @updated-reservation="handleReservationCreatedOrUpdated"
      @delete-reservation="handleReservationCreatedOrUpdated"
    >
    </ReservationCreateDialog>

    <ReservationListSendSmsDialog
      :openDialog="openSendSmsDialog"
      :reservations="reservationsSmsDialog"
      @close ="openSendSmsDialog = false"
    ></ReservationListSendSmsDialog>  
  </div>
</template>

<script>
import StandardDialog from "@/components/common/StandardDialog";
import ReservationSelectDialog from "@/components/common/StandardDialog";
import ResponsiveCardSelector from "@/components/common/ResponsiveCardSelector";
import DatePicker from "@/components/common/DatePickerCommon.vue";
import TypeDataTable from "@/components/common/TypeDataTable.vue";
import ReservationCreateDialog from "@/components/bar/reservation/ReservationCreateDialog";
import ReservationListSendSmsDialog from "@/components/bar/reservation/ReservationListSendSmsDialog";

import zoneService from "@/services/bar/zone.service";

export default {
  name: "TablesCalendar",
  components: {
    DatePicker,
    StandardDialog,
    ResponsiveCardSelector,
    ReservationSelectDialog,
    TypeDataTable,
    ReservationCreateDialog,
    ReservationListSendSmsDialog,
  },
  data() {
    return {
      reservations: undefined,
      selectedZone: undefined,
      zones: undefined,
      localOpenDialog: false,
      tables: undefined,
      dayReservation: undefined,
      focus: new Date().toISOString().substring(0, 10),
      dateToShow: new Date().toLocaleString("it-IT", {
        month: "2-digit",
        day: "2-digit",
        year: "numeric",
      }),
      openCalendar: true,
      openReservationCreateDialog: false,
      tableSelectedCreateDialog: undefined,
      dayPeriodReservationCreateDialog: undefined,
      reservationCreateDialog: undefined,
      datePickerValue: undefined,
      openSendSmsDialog:false,
      reservationsSmsDialog: undefined,
      headers: [
        { text: "Tavolo", value: "name", type: "custom", width: "12%" },
        { text: "Pranzo", value: "lunch", type: "custom", width: "30%" },
        { text: "Aperitivo", value: "appetizer", type: "custom", width: "29%" },
        { text: "Cena", value: "dinner", type: "custom", width: "29%" },
      ],
    };
  },
  props: {},
  async mounted() {
    this.dayReservation = new Date();
    await this.fetchZones();
    await this.fetchZoneTables();
    await this.fetchReservations();
  },
  methods: {
    close() {
      this.localOpenMenu = false;
      this.$emit("close");
    },
    async fetchZones() {
      try {
        let zones = await zoneService.list();
        this.selectedZone = zones[0];
        this.zones = zones;
        return;
      } catch (error) {
        console.log(error);
      }
    },
    async fetchZoneTables() {
      try {
        let tables = await zoneService.getZoneTables(
          Number(this.selectedZone.id)
        );
        this.tables = tables;
        return;
      } catch (error) {
        console.log(error);
      }
    },
    async fetchReservations() {
      try {
        let reservations = await zoneService.zoneTableListWithReservationPerDay(
          Number(this.selectedZone.id),
          this.focus
        );
        this.reservationsSmsDialog = [];
        for (const reservation of reservations) {
          if (!!reservation.lunch) {
            reservation.lunch.startTimeString = new Date(
              reservation.lunch.startDate
            ).toLocaleTimeString("it-IT", {
              hour: "2-digit",
              minute: "2-digit",
            });
            reservation.lunch.lastTimeString = new Date(
              reservation.lunch.endDate
            ).toLocaleTimeString("it-IT", {
              hour: "2-digit",
              minute: "2-digit",
            });
            this.reservationsSmsDialog.push(reservation.lunch)
          }
          if (!!reservation.appetizer) {
            reservation.appetizer.startTimeString = new Date(
              reservation.appetizer.startDate
            ).toLocaleTimeString("it-IT", {
              hour: "2-digit",
              minute: "2-digit",
            });
            reservation.appetizer.lastTimeString = new Date(
              reservation.appetizer.endDate
            ).toLocaleTimeString("it-IT", {
              hour: "2-digit",
              minute: "2-digit",
            });
            this.reservationsSmsDialog.push(reservation.appetizer)
          }
          if (!!reservation.dinner) {
            reservation.dinner.startTimeString = new Date(
              reservation.dinner.startDate
            ).toLocaleTimeString("it-IT", {
              hour: "2-digit",
              minute: "2-digit",
            });
            reservation.dinner.lastTimeString = new Date(
              reservation.dinner.endDate
            ).toLocaleTimeString("it-IT", {
              hour: "2-digit",
              minute: "2-digit",
            });
            this.reservationsSmsDialog.push(reservation.dinner)
          }
        }
        this.reservations = reservations;
        return;
      } catch (error) {
        console.log(error);
      }
    },
    async handleChangeZone(newZone) {
      this.selectedZone = newZone;
      await this.fetchZoneTables();
      await this.fetchReservations();
    },
    setToday() {
      let date = new Date();
      this.focus = date.toISOString().substring(0, 10);
      (this.dateToShow = new Date(this.focus).toLocaleString("it-IT", {
        month: "2-digit",
        day: "2-digit",
        year: "numeric",
      })),
        this.fetchReservations();
    },
    prev() {
      let date = new Date(this.focus);
      date.setDate(date.getDate() - 1);
      this.focus = date.toISOString().substring(0, 10);
      (this.dateToShow = new Date(this.focus).toLocaleString("it-IT", {
        month: "2-digit",
        day: "2-digit",
        year: "numeric",
      })),
        this.fetchReservations();
    },
    next() {
      let date = new Date(this.focus);
      date.setDate(date.getDate() + 1);
      this.focus = date.toISOString().substring(0, 10);
      (this.dateToShow = new Date(this.focus).toLocaleString("it-IT", {
        month: "2-digit",
        day: "2-digit",
        year: "numeric",
      })),
        this.fetchReservations();
    },
    openCreateDialog(item, periodDayToSet) {
      this.tableSelectedCreateDialog = {};
      this.reservationCreateDialog = {};
      this.tableSelectedCreateDialog.zone = this.selectedZone;
      this.tableSelectedCreateDialog.code = item.code;
      this.tableSelectedCreateDialog.id = item.tableId;
      this.reservationCreateDialog.reservationDate = this.focus;
      this.reservationCreateDialog.prefixCellphone = item.prefixCellphone;
      this.dayPeriodReservationCreateDialog = periodDayToSet;
      this.openReservationCreateDialog = true;
    },
    async handleReservationCreatedOrUpdated() {
      await this.fetchReservations();
      this.$emit('change-in-reservations')
      this.openReservationCreateDialog = false;
    },
    changeDateFromDatePicker() {
      (this.dateToShow = new Date(this.focus).toLocaleString("it-IT", {
        month: "2-digit",
        day: "2-digit",
        year: "numeric",
      })),
        this.fetchReservations();
    },
    openEditDialog(reservation, codeTable, tableId, periodDayToSet) {
      this.tableSelectedCreateDialog = {};
      this.reservationCreateDialog = { ...reservation };
      this.tableSelectedCreateDialog.zone = this.selectedZone;
      this.tableSelectedCreateDialog.code = codeTable;
      this.tableSelectedCreateDialog.id = tableId;
      this.reservationCreateDialog.reservationDate = this.focus;
      this.reservationCreateDialog.id = reservation.reservationId;
      this.reservationCreateDialog.prefixCellphone = reservation.prefixCellphone;
      this.dayPeriodReservationCreateDialog = periodDayToSet;
      this.openReservationCreateDialog = true;
    },
  },
  watch: {},
};
</script>

<style>
.clickableEmptyCell {
  width: 100%;
  height: 100%;
  opacity: 0;
}
</style>