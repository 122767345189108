var render = function render(){var _vm=this,_c=_vm._self._c;return _c('StandardDialog',{attrs:{"dialog-width":"90vw","dialog-height":"calc(90vh-1)","persistent":"","title":"Seleziona la prenotazione"},scopedSlots:_vm._u([{key:"footer",fn:function(){return [_c('v-spacer'),_c('v-btn',{attrs:{"text":"","color":"error"},on:{"click":_vm.close}},[_vm._v("Chiudi")])]},proxy:true}]),model:{value:(_vm.localOpenDailyZones),callback:function ($$v) {_vm.localOpenDailyZones=$$v},expression:"localOpenDailyZones"}},[(_vm.activeZones.length > 0)?_c('div',[_c('div',[_c('v-row',[_c('v-col',{attrs:{"cols":"3"}},[_c('v-subheader',{staticClass:"d-flex justify-start align-center font-weight",staticStyle:{"font-size":"20px"}},[_vm._v("Zone del giorno ")])],1),_c('v-col',[_c('v-card',{staticClass:"mt-3 d-flex flex-wrap justify-center align-center",attrs:{"max-width":"200","height":"50","color":_vm.zoneSelected.zone.color}},[_c('div',{staticClass:"font-italic font-weight-normal",staticStyle:{"font-size":"20px"},attrs:{"width":"100%"}},[_vm._v(" "+_vm._s(_vm.zoneSelected.zone.name)+" ")])])],1)],1),_c('v-row',[_c('v-col',{attrs:{"cols":"2"}},[_c('div',{staticClass:"ma-3 d-flex justify-center align-center"},[_c('ResponsiveCardSelector',{attrs:{"items":_vm.activeZones,"returnObject":true,"item-key":"key","card-max-height":"40","card-min-height":"40","card-max-width":"120","card-min-width":"120","multiple":false},scopedSlots:_vm._u([{key:"item",fn:function({ item, cardMinHeight }){return [_c('div',{staticClass:"d-flex flex-column justify-center align-center",style:({
                    'min-height':
                      cardMinHeight.indexOf('px') != -1
                        ? cardMinHeight
                        : cardMinHeight + 'px',
                    'background-color': item.zone.color,
                  })},[_c('div',{staticClass:"font-weight-medium"},[_vm._v(" "+_vm._s(item.zone.name)+" ")])])]}}],null,false,1007771013),model:{value:(_vm.zoneSelected),callback:function ($$v) {_vm.zoneSelected=$$v},expression:"zoneSelected"}})],1)]),_c('v-col',{staticClass:"mt-4",attrs:{"cols":"10"}},[_c('ZoneMap',{attrs:{"zone-selected":_vm.zoneSelected},on:{"handle-selection-element":_vm.handleSelectionElement}})],1)],1)],1)]):_c('div',{staticClass:"d-flex justify-center align-center"},[_vm._v(" Nessuna zona attiva oggi ")]),_c('ReservationSelectDialog',{attrs:{"dialog-width":"360","dialog-height":"calc(360px-10px)","persistent":"","title":_vm.titleReservationSelectDialog},model:{value:(_vm.openReservationSelectDialog),callback:function ($$v) {_vm.openReservationSelectDialog=$$v},expression:"openReservationSelectDialog"}},[_c('v-subheader',{staticClass:"d-flex jutify-center align-center"},[_vm._v(" Associa la pronotazione al tavolo ")]),_c('v-list',{attrs:{"flat":""}},[_vm._l((_vm.reservations),function(reservation,i){return [_c('v-list-item-group',{key:i,attrs:{"color":"primary"}},[_c('v-list-item',{key:i,attrs:{"disabled":_vm.statusClosed(reservation)},on:{"click":function($event){return _vm.changeCurrentReservation(reservation)}}},[_c('v-list-item-title',[_c('div',{style:({
                  'text-decoration': _vm.statusClosed(reservation)
                    ? 'line-through'
                    : '',
                })},[_vm._v(" "+_vm._s(reservation.reservationName)+" ")]),_c('DateTimeFormattor',{attrs:{"with-time":true,"format":"dd/mm/yyyy"},model:{value:(reservation.startDate),callback:function ($$v) {_vm.$set(reservation, "startDate", $$v)},expression:"reservation.startDate"}})],1)],1)],1)]})],2)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }