<template>
  <div>
    <FullScreenDialog
      persistent
      v-model="localOpenDialog"
      title="Creazione Menu"
      :can-close="false"
    >
      <template v-slot:header-actions>
        <v-btn icon small class="ml-3" @click="close"
          ><v-icon>mdi-close</v-icon></v-btn
        >
        <v-btn icon small :disabled="!canSave" class="ml-3"
          ><v-icon>mdi-check</v-icon></v-btn
        >
      </template>
      <template v-slot:content>
        <v-row class="pa-1">
        <v-col>
          <v-text-field
            max-width="5vw"
            :hide-details="true"
            dense
            filled
            v-model="menuName"
            label="Nome"
          ></v-text-field>
        </v-col>
        <v-col cols="4" class="flex-grow-0 flex-shrink-1">
          <v-text-field
            max-width="5vw"
            :hide-details="true"
            dense
            filled
            v-model="menuDescription"
            label="Descrizione"
          ></v-text-field>
        </v-col>
        <v-col>
          <DepartmentsAutocomplete
            label="Seleziona Reparto Magazzino"
            @input="fetchItemsFilteredByDepartment"
            multiple
          ></DepartmentsAutocomplete>
        </v-col>
        <v-col>
          <!--<ServiceTypesCategoryAutocomplete
            label="Seleziona Reparto Servizi"
            :isBar="true"
            @input="fetchServicesFilteredByCategory"
            multiple
          ></ServiceTypesCategoryAutocomplete>-->
        </v-col>
        <v-col class="d-flex justify-center align-center">
          <StandardColorPicker
            v-model="localMenuSelectedColor"
          ></StandardColorPicker>
          <p class="ma-3 font-italic font-weight-light">Colore Menu</p>
        </v-col>
      </v-row>
      <v-row>
        <v-col>
           <DescriptiveGroupedSelectList
            v-model="selectedItems"
            :items="filteredItems"
            :filters="filterForItems"
            items-key="items"
            items-text-key="description"
            categories-text-key="name"
            title-to-select='Lista Prodotti'
            title-selected="Prodotti Selezionati"
           ></DescriptiveGroupedSelectList>

           <DescriptiveGroupedSelectList
            v-model="selectedServices"
            :items="filteredServiceItems"
            items-key="services"
            items-text-key="name"
            categories-text-key="name"
            title-to-select='Lista Servizi'
            title-selected="Servizi Selezionati"
           ></DescriptiveGroupedSelectList>
        </v-col>
       
      </v-row>
      </template>
      <template v-slot:footer-actions>
        <v-btn text color="error" @click="close">Annulla</v-btn>
        <v-btn text :disabled="!canSave" @click="saveMenu">Salva</v-btn>
      </template>
    </FullScreenDialog>

    <ErrorDialog
      dialog-width="360"
      dialog-height="360"
      persistent
      v-model="showErrorDialog"
      title="Errore"
    >
      {{ errorMessage }}
    </ErrorDialog>
  </div>
</template>

<script>
import StandardDialog from "@/components/common/StandardDialog.vue";
import FullScreenDialog from "@/components/common/FullScreenDialog.vue";
import ErrorDialog from "@/components/common/StandardDialog.vue";
import StandardColorPicker from "@/components/common/StandardColorPicker.vue";
import DescriptiveGroupedSelectList from "@/components/common/DescriptiveGroupedSelectList.vue";
import GroupedSelectList from '@/components/common/GroupedSelectList';
import DepartmentsAutocomplete from '@/components/common/DepartmentsAutocomplete.vue'
import ServiceTypesCategoryAutocomplete from '@/components/common/ServiceTypesCategoryAutocomplete.vue'


import menuService from "@/services/bar/menu.service.js";
import itemService from "@/services/warehouse/items.service.js";
import servicesService from '@/services/services/services.service.js'
import serviceTypesCategoriesService from "@/services/serviceTypesCategories/serviceTypesCategories.service.js"
import departmentsService from "@/services/departments/departments.service";


export default {
  name: "MenuCreateDialog",
  components: {
    StandardDialog,
    ErrorDialog,
    StandardColorPicker,
    DescriptiveGroupedSelectList,
    FullScreenDialog,
    GroupedSelectList,
    DescriptiveGroupedSelectList,
    DepartmentsAutocomplete,
    ServiceTypesCategoryAutocomplete
  },
  props: {
    openDialog: {
      type: Boolean,
      default: false,
    },
    menuSelectedColor: {
      type: String,
      default: "",
    },
  },
  data: function () {
    return {
      localOpenDialog: false,
      showErrorDialog: false,
      errorMessage: "",
      menuName: "",
      menuDescription: "",
      listItemsToCreate: [],
      filteredItems: [],
      filteredServiceItems: [],
      selectedItems: [],
      selectedServices: [],
      menuEntities: [],
      localMenuSelectedColor: "",
      departmentId: undefined,
      filterForItems: []
    };
  },
  computed: {
    canSave() {
      return (this.menuName && (this.selectedItems.length > 0 || this.selectedServices.length > 0))
    },
  },
  watch:{
  },
  mounted() {
    this.localOpenDialog = this.openDialog;
    this.localMenuSelectedColor = this.menuSelectedColor;

    let self = this
    serviceTypesCategoriesService.list(undefined, undefined, {isBar: true}).then((serviceTypes) => {
      let categoryIds = serviceTypes.rows.map(service=>service.id);
      
      let mapped = serviceTypes.rows.map(service=>{
        return service.serviceTypes
      })
      for(let category of mapped){
        self.filteredServiceItems = [...self.filteredServiceItems, ...category]
      }

/*       for(let categoryId of categoryIds)
        serviceTypesCategoriesService.get(categoryId).then(category=>{
          console.log(category.serviceTypes)
          self.filteredServiceItems = [...self.filteredServiceItems, ...category.serviceTypes]
      }) */
    })

    this.filteredItems = []
    departmentsService
      .list()
      .then((departments) => {
        for(let department of departments.rows)
        itemService
          .listItemsFromDepartment(department.id)
          .then((items) => {
            for(let item of items)
              item.name = item.name + " (" + department.name + ")"
                
            this.filteredItems = [...this.filteredItems, ...items]
          })
          .catch((error) => {
            console.log(error);
          });
      })
      .catch((error) => {
          console.log(error);
      });
  },
  methods: {
    close() {
      this.$emit("close");
    },
    saveMenu() {
      menuService.create(this._buildMenuObject()).then((createdMenu) => {
        const entities = this._buildEntities(this.selectedItems,this.selectedServices)
        menuService
          .addEntitiesToMenu(createdMenu.id, entities)
          .then(() => {
            this.$emit("saved-menu");
          })
          .catch((error) => {
            this.errorMessage = "Errore nella creazione del menù";
            this.showErrorDialog = true;
            console.log(error);
        });
      });
    },
    fetchItemsFilteredByDepartment(departmentIds) {
      this.filterForItems = []

      for(let departmentId of departmentIds)
        itemService
          .listItemsFromDepartment(departmentId)
          .then((items) => {
            items = items.map(item => item.id)
            this.filterForItems = [...this.filteredItems, ...items]
          })
          .catch((error) => {
            console.log(error);
          });
    },
    fetchServicesFilteredByCategory(categoryIds) {
      this.filteredServiceItems = []
      this.selectedServices = []

      if(!categoryIds) return
      //this.categoryId = categoryId
      this.filteredServiceItems = []
      for(let categoryId of categoryIds)
        serviceTypesCategoriesService.get(categoryId).then(category=>{
          this.filteredServiceItems = [...this.filteredServiceItems, ...category.serviceTypes]
        })
    },
    _buildMenuObject() {
      return {
        name: this.menuName,
        description: this.menuDescription,
        createdAt: new Date(),
        color: this.localMenuSelectedColor,
        departmentId: this.departmentId,
        categoryId: this.categoryId
      };
    },

    _buildEntities(items,services){
      let entities = []
      items.map((item) => {
        entities.push({
          itemId: Number(item.id),
        })
      })
      services.map((service) => {
        entities.push({
          serviceId: Number(service.id)
        })
      })
      return entities
    }
  },
};
</script>