<template>
  <div>
      <ReservationList
          :reservations="reservationList"
          @edit-reservation="openEditReservation"
          @delete-reservation="deleteReservation"
      >
      </ReservationList>
  </div>  
</template>

<script>
import ReservationList from "@/components/bar/reservation/ReservationList.vue";
import zoneActiveHandler from "@/components/bar/handlers/zoneActiveHandler.js";

export default {
  name:"ReservationListByZone",
  components:{
    ReservationList
  },
  mounted(){
    this.featReservationByZone(this.zoneId)
  },
  data(){
    return {
      reservationList: []
    } 
  },
  props:{
    tablesIdsListByZone:{
      type:Array,
      default:()=>[]
    },
    zoneId:{
      type:Number,
      default:0
    }
  },
  methods:{
    featReservationByZone(zoneId){
      zoneActiveHandler
          .loadReservations({
            zoneId: zoneId,
          })
          .then((reservations) => {
            this.reservationList = reservations;
            zoneActiveHandler.on("reload-reservations", ({ reservations }) => {
              this.reservationList = reservations;
            });
          });
    },
    openEditReservation(){

    },
    deleteReservation(){

    }
  }
}
</script>

<style>

</style>