<template>
  <v-row>
    <v-col>
      <div class="text-h6 ma-1">{{ titleToSelect }}</div>

      <GroupedSelectList
        :items="items"
        :categories-identifier-key="categoriesIdentifierKey"
        :items-identifier-key="itemsIdentifierKey"
        :categories-text-key="categoriesTextKey"
        :items-key="itemsKey"
        :items-text-key="itemsTextKey"
        :filters="filters"
        :multiple="multiple"
        :selected-items.sync="selectedItems"
        v-model="localSelected"
        @input="$emit('input', $event)"
      ></GroupedSelectList>
    </v-col>
    <v-col>
      <div class="text-h6 ma-1">{{ titleSelected }}</div>
      <!-- <v-chip v-for="enablingType in enablingTypesSelected" :key="enablingType.id" label class="ma-1">{{enablingType.code}}</v-chip> -->
      <v-list 
          v-for="category in selectedItems"
          :key="category[categoriesIdentifierKey]" 
          style="width: 100%;">
        <v-list-group 
         no-action
        >

          <template v-slot:activator>
            <v-list-item-content>
              <v-list-item-title v-text="category[categoriesTextKey]"></v-list-item-title>
            </v-list-item-content>
          </template>

          <v-list-item
            v-for="item in category[itemsKey]"
            :key="item[itemsIdentifierKey]"
          >
            <v-list-item-content>
              <v-list-item-title>{{item[itemsTextKey]}}</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-list-group>
      </v-list>
    </v-col>
  </v-row>
</template>

<script>
import GroupedSelectList from '@/components/common/GroupedSelectList';

export default {
  name: "DescriptiveGroupedSelectList",
  components: {
    GroupedSelectList,
  },
  data: function() {
    return {
      localSelected: this.value,
      selectedItems: [],
    }
  },
  props: {
    items: {
      type: Array,
      default: function() {
        return []
      }
    },
    value: {
      type: Array,
      default: function() {
        return []
      }
    },
    categoriesIdentifierKey: {
      type: String,
      default: 'id'
    },
    itemsIdentifierKey: {
      type: String,
      default: 'id'
    },
    categoriesTextKey: {
      type: String,
      default: 'code'
    },
    itemsKey: {
      type: String,
      default: 'items'
    },
    itemsTextKey: {
      type: String,
      default: 'code'
    },
    filters: {
      type: Array,
      default: function() {
        return []
      }
    },
    multiple: {
      type: Boolean,
      default: true
    },
    titleSelected: {
      type: String,
      default: "Lista Selezionati"
    },
    titleToSelect: {
      type: String,
      default: "Lista da Selezionare"
    }
  },
  mounted() {
  },
  methods: {
    
  },
  watch: {
    value(newVal) {
      this.localSelected = newVal;
    }
  }
}
</script>