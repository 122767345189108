var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('v-subheader',{staticClass:"font-italic font-weight-light"},[_vm._v(" Elenco Menu ")]),_c('MenuCardList',{attrs:{"items":_vm.menuList,"returnObject":true,"card-max-height":"100","card-min-height":"100","card-max-width":"100","card-min-width":"100"},on:{"input":_vm.selectedMenu},scopedSlots:_vm._u([{key:"item",fn:function({ item, cardMinHeight }){return [_c('div',{staticClass:"pa-2 d-flex flex-column justify-center align-center",style:({
          'min-height':
            cardMinHeight.indexOf('px') != -1
              ? cardMinHeight
              : cardMinHeight + 'px',
          'background-color': item.color,
        })},[_c('div',{staticClass:"mt-2"},[_vm._v(_vm._s(item.name))]),(_vm.localDeleteMenu)?_c('v-btn',{staticClass:"remove",attrs:{"icon":""}},[_c('v-icon',{on:{"click":function($event){return _vm.remove(item)}}},[_vm._v("mdi-close")])],1):_vm._e()],1)]}}])}),(_vm.openDetailMenu)?_c('MenuUpdateDialog',{attrs:{"open-dialog":_vm.openDetailMenu,"menu-id":_vm.menuIdSelected},on:{"close":_vm.close,"updated-menu":_vm.reloadMenusList}}):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }