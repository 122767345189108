var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticStyle:{"overflow-x":"hidden","overflow-y":"hidden"}},[_c('div',[(_vm.selectedComponent !== '')?_c('v-btn',{attrs:{"icon":""},on:{"click":_vm.onBackSettings}},[_c('v-icon',[_vm._v("mdi-arrow-left")])],1):_vm._e()],1),_c('div',{staticClass:"mt-10 d-flex justify-center align-center",attrs:{"no-gutters":""}},[_c('div',{staticClass:"d-flex flex-column justify-center align-center",staticStyle:{"flex":"50%"}},[(!_vm.selectedCategory)?_c('ResponsiveCardSelector',{attrs:{"items":_vm.categoriesList,"returnObject":true,"item-key":"name","card-max-height":"200","card-min-height":"200","card-max-width":"200","card-min-width":"200"},scopedSlots:_vm._u([{key:"item",fn:function({ item, cardMinHeight }){return [_c('div',{staticClass:"pa-2 d-flex flex-column justify-center align-center",style:({
              'min-height':
                cardMinHeight.indexOf('px') != -1
                  ? cardMinHeight
                  : cardMinHeight + 'px',
              'background-color': item.color,
            })},[_c('div',{staticClass:"mt-2 font-weight-medium",staticStyle:{"font-size":"150%"}},[_vm._v(" "+_vm._s(item.name)+" ")]),_c('v-icon',{attrs:{"large":""}},[_vm._v(_vm._s(item.icon))])],1)]}}],null,false,2285407024),model:{value:(_vm.selectedCategory),callback:function ($$v) {_vm.selectedCategory=$$v},expression:"selectedCategory"}}):_vm._e()],1)]),_c(_vm.selectedComponent,{tag:"component",class:{
      'section-fade-in': _vm.selectedComponent !== '',
    }})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }