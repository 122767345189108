<template>
  <div>
    <StandardDialog
      dialog-width="50vw"
      dialog-height="calc(90vh-100px)"
      persistent
      v-model="localOpenDialog"
      v-if="!!tableSelected"
      :title="
        'Prenotazione Tavolo ' +
        tableSelected.code +
        ' in ' +
        tableSelected.zone.name
      "
      style="overflow-y: hidden"
    >
      <template
        v-slot:header-actions
        v-if="!!reservationSelected && !!reservationSelected.id"
      >
        <v-tooltip allow-overflow bottom>
          <template v-slot:activator="{ on, attrs }">
            <v-btn text @click="deleteReservation" v-bind="attrs" v-on="on">
              <v-icon>mdi-trash-can</v-icon>
            </v-btn>
          </template>
          Elimina prenotazione
        </v-tooltip>
<!--          <v-tooltip allow-overflow bottom>
          <template v-slot:activator="{ on, attrs }">
            <v-btn text @click="openSendSmsDialog" v-bind="attrs" v-on="on">
              <v-icon>mdi-message-text</v-icon>
            </v-btn>
          </template>
          Invia un sms al cliente
        </v-tooltip> -->
      </template>
      <ReservationForm
        :bus="bus"
        :valid.sync="canSave"
        :dayPeriodProps="dayPeriodProps"
        :table-selected="tableSelected"
        :reservation-selected="reservationSelected"
        @saved-reservation="handleSaveReservation"
        @updated-reservation="handleUpdateReservation"
        @close="close"
      >
      </ReservationForm>
      <template v-slot:footer-actions>
        <v-btn text color="error" @click="close">Chiudi</v-btn>
        <v-btn text :disabled="!canSave" @click="saveReservation">Salva</v-btn>
      </template>
    </StandardDialog>
    <StandardDialog
      v-model="smsDialog"
      title="Invia Sms"
      :dialog-height="null"
      dialog-max-width="80vw"
    >
      <SendSmsModel class="mt-2"
        :customers="customers"
        :eventGroup="eventGroup"
        :numbers="numbers"
        :whatsapp="whatsapp"
      ></SendSmsModel>
    </StandardDialog>
  </div>
</template>

<script>
import StandardDialog from "@/components/common/StandardDialog.vue";
import DateTimePickerCircular from '@/components/common/DateTimePickerCircular.vue';
import ReservationForm from "@/components/bar/reservation/ReservationForm.vue";
import tableService from "@/services/bar/table.service";
import reservationService from "@/services/bar/reservation.service";
import SendSmsModel from "@/components/calendar/SendSmsModel.vue";
import settingsService from "../../../services/settings/settings.service";

import Vue from "vue";

export default {
  components: {
    ReservationForm,
    StandardDialog,
    SendSmsModel,
  },
  computed: {
    isCurrent() {
      return (
        this.tableSelected.currentReservationId === this.reservationSelected.id
      );
    },
  },
  props: {
    openDialog: {
      type: Boolean,
      default: false,
    },
    tableSelected: {
      type: Object,
      default: undefined,
    },
    newReservation: {
      type: Object,
      default: undefined,
    },
    dayPeriodProps: {
      type: String,
      default: undefined,
    },
  },
  data() {
    return {
      localOpenDialog: false,
      reservationSelected: this.newReservation,
      canSave: false,
      bus: new Vue(),
      showDialog: false,
      canDelete: false,
      smsDialog: false,
      customers: [],
      eventGroup: undefined,
      numbers: [],
      whatsapp: undefined
    };
  },
  async mounted() {
    this.localOpenDialog = this.openDialog;
    if(!!this.reservationSelected && !!this.reservationSelected.id){
      let customer = {
        firstname: this.reservationSelected.reservationName,
      }
      let eventGroup = {
        startDate: new Date(this.reservationSelected.startDate)
      }
      let number = this.reservationSelected.prefixCellphone + this.reservationSelected.reservationPhone
      this.numbers.push(number)
      this.customers.push(customer)
      this.eventGroup = {...eventGroup}
      this.whatsapp = (await settingsService.get('whatsappReservation')).value
    }
  },
  methods: {
    deleteReservation() {
      if (!!this.reservationSelected) {
        if (!this.reservationSelected.billReservation) {
          this.canDelete = true;
          reservationService
            .delete(this.reservationSelected)
            .then((value) => {
              this.reservationDeletedEmit();
            })
            .catch((err) => {});
        } else {
          this.canDelete = false;
        }
      }
    },
    handleSaveReservation() {
      this.$emit("saved-reservation");
    },
    handleUpdateReservation() {
      this.$emit("updated-reservation");
    },
    saveReservation() {
      this.bus.$emit("save-reservation");
    },
    reservationDeletedEmit() {
      this.$emit("delete-reservation");
    },
    close() {
      this.$emit("close");
    },
    openMenuBill() {
      let tableWithReservation = {
        id: this.tableSelected.id,
        currentReservationId: this.reservationSelected.id,
      };

      tableService.update(tableWithReservation).then((value) => {
        this.localOpenDialog = false;
        this.$emit("open-menu", this.reservationSelected);
      });
    },
    openSendSmsDialog() {
      this.smsDialog = true;
    },
  },
  watch: {
    openDialog(newVal) {
      this.localOpenDialog = newVal;
    },
    tableSelected(newVal) {
      if (!!newVal.i && !newVal.code) this.tableSelected.code = newVal.i;
      else if (!!newVal.code && !newVal.i) this.tableSelected.i = newVal.code;
    },
  },
};
</script>

<style>
</style>