<template>
  <div>
    <FullScreenDialog
      persistent
      v-model="localOpenDialog"
      title="Modifica Menu"
      :can-close="false"
    >
      <template v:slot:activator></template>
      <template v-slot:header-actions>
        <v-btn icon small class="ml-3" @click="close"
          ><v-icon>mdi-close</v-icon></v-btn
        >
        <v-btn icon small :disabled="!canSave" class="ml-3"
          ><v-icon>mdi-check</v-icon></v-btn
        >
      </template>
      <template v-slot:content>
        <v-row class="pa-1">
        <v-col>
          <v-text-field
            max-width="5vw"
            :hide-details="true"
            dense
            filled
            v-model="menuName"
            label="Nome"
          ></v-text-field>
        </v-col>
        <v-col cols="4" class="flex-grow-0 flex-shrink-1">
          <v-text-field
            max-width="5vw"
            :hide-details="true"
            dense
            filled
            v-model="menuDescription"
            label="Descrizione"
          ></v-text-field>
        </v-col>
        <v-col>
          <DepartmentsAutocomplete
            label="Seleziona Reparto Magazzino"
            v-model="departmentId"
            @input="fetchItemsFilteredByDepartment"
            multiple
          ></DepartmentsAutocomplete>
        </v-col>
        <v-col>
          <!--<ServiceTypesCategoryAutocomplete
            v-model="categoryId"
            label="Seleziona Reparto Servizi"
            :isBar="true"
            readonly
            :clearable="false"
          ></ServiceTypesCategoryAutocomplete>-->
        </v-col>
        <v-col class="d-flex justify-center align-center">
          <StandardColorPicker v-model="localMenuSelectedColor">
          </StandardColorPicker>
          <p class="ma-3 font-italic font-weight-light">Colore Menu</p>
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <DescriptiveGroupedSelectList
            :items="filteredItems"
            items-key="items"
            items-text-key="description"
            categories-text-key="name"
            :title-to-select="'Lista Prodotti'"
            :title-selected="'Menù'"
            v-model="selectedItems"
            :filters="filterForItems"
          ></DescriptiveGroupedSelectList>
          <DescriptiveGroupedSelectList
            v-model="selectedServices"
            :items="filteredServiceItems"
            items-key="services"
            items-text-key="name"
            categories-text-key="name"
            title-to-select='Lista Servizi'
            title-selected="Servizi Selezionati"
          ></DescriptiveGroupedSelectList>
        </v-col>  
      </v-row>
      </template>
      <template v-slot:footer-actions>
        <v-btn text color="error" @click="close">Annulla</v-btn>
        <v-btn text :disabled="!canSave" @click="saveMenu">Salva</v-btn>
      </template>
    </FullScreenDialog>
  </div>
</template>

<script>
import StandardColorPicker from "@/components/common/StandardColorPicker.vue";
import StandardDialog from "@/components/common/StandardDialog.vue";
import FullScreenDialog from "@/components/common/FullScreenDialog.vue";
import DescriptiveGroupedSelectList from "@/components/common/DescriptiveGroupedSelectList.vue";
import DepartmentsAutocomplete from '@/components/common/DepartmentsAutocomplete.vue'
import ServiceTypesCategoryAutocomplete from '@/components/common/ServiceTypesCategoryAutocomplete.vue'

import menuService from "@/services/bar/menu.service.js";
import itemService from "@/services/warehouse/items.service.js";
import servicesService from '@/services/services/services.service.js'
import serviceTypesCategoriesService from "@/services/serviceTypesCategories/serviceTypesCategories.service.js"
import departmentsService from "@/services/departments/departments.service";


export default {
  name: "MenuUpdateDialog",
  components: {
    StandardDialog,
    StandardColorPicker,
    DescriptiveGroupedSelectList,
    FullScreenDialog,
    DepartmentsAutocomplete,
    ServiceTypesCategoryAutocomplete
  },
  props: {
    openDialog: {
      type: Boolean,
      default: false,
    },
    menuId: {
      type: Number,
      default: 0,
    },
  },
  computed: {
    canSave() {
      return (this.menuName && (this.selectedItems.length > 0 || this.selectedServices.length > 0))
    },
  },
  data: function () {
    return {
      localOpenDialog: false,
      menuName: "",
      menuDescription: "",
      localMenuSelectedColor: "",
      filteredServiceItems: [],
      filteredItems: [],
      selectedServices: [],
      selectedItems: [],
      departmentId: undefined,
      categoryId: undefined,
      filterForItems: []
    };
  },
  mounted() {
    this.loadMenu(this.menuId);
    this.localOpenDialog = this.openDialog

    let self = this
    serviceTypesCategoriesService.list(undefined, undefined, {isBar: true}).then((serviceTypes) => {
      let categoryIds = serviceTypes.rows.map(service=>service.id);

      let mapped = serviceTypes.rows.map(service=>{
        return service.serviceTypes
      })
      for(let category of mapped){
        self.filteredServiceItems = [...self.filteredServiceItems, ...category]
      }

     /*  for(let categoryId of categoryIds)
        serviceTypesCategoriesService.get(categoryId).then(category=>{
          self.filteredServiceItems = [...self.filteredServiceItems, ...category.serviceTypes]
      }) */
    })

    this.filteredItems = []
    departmentsService
      .list()
      .then((departments) => {
        for(let department of departments.rows)
        itemService
          .listItemsFromDepartment(department.id)
          .then((items) => {
            for(let item of items)
              item.name = item.name + " (" + department.name + ")"
                
            this.filteredItems = [...this.filteredItems, ...items]
          })
          .catch((error) => {
            console.log(error);
          });
      })
      .catch((error) => {
          console.log(error);
      });
  },
  methods: {
    close() {
      this.$emit("close");
    },
    loadMenu(menuId) {
      menuService.get(menuId).then((menu) => {
        if (menu) this.setLocalMenu(menu);
        this.loadBarAndMenuEntities(menuId);
      });
    },
    loadBarAndMenuEntities(menuId) {
      menuService
        .menuEntityList(menuId)
        .then((menuEntities) => {
          this.selectedItems = menuEntities.items
          this.selectedServices = menuEntities.services
        })
        .catch((error) => {
          console.log(error);
        });
    },
    setLocalMenu(menu) {
      this.menuName = menu.name;
      this.menuDescription = menu.description;
      this.localMenuSelectedColor = menu.color;
      //this.departmentId = menu.departmentId
      //this.categoryId = menu.categoryId
    },
    saveMenu() {
      menuService
        .update({
          menu: this._buildMenuObject(),
          entities: this._buildEntities(this.selectedItems,this.selectedServices)
        })
        .then(() => {
          this.$emit("updated-menu");
        })
        .catch((error) => {
          this.errorMessage = "Errore nell'aggiornamento del menù";
          this.showErrorDialog = true;
          console.log(error);
        });
    },
    _buildMenuObject() {
      return {
        id: this.menuId,
        name: this.menuName,
        description: this.menuDescription,
        createdAt: new Date(),
        color: this.localMenuSelectedColor,
      };
    },
    _buildEntities(items,services){
      let entities = {
        items:[],
        services:[]
      }
      items.map((item) => {
        entities.items.push(Number(item.id))
      })
      services.map((service) => {
        entities.services.push(Number(service.id))
      })
      return entities
    },
    fetchItemsFilteredByDepartment(departmentIds) {
      this.filterForItems = []

      for(let departmentId of departmentIds)
        itemService
          .listItemsFromDepartment(departmentId)
          .then((items) => {
            items = items.map(item => item.id)
            this.filterForItems = [...this.filteredItems, ...items]
          })
          .catch((error) => {
            console.log(error);
          });
    },
  },
};
</script>