<template>
  <div>
    <v-row>
      <v-col>
        <v-subheader class="font-italic font-weight-light py-0"
          >Creazione Nuovo Menu
        </v-subheader>
        <v-row class="mt-4 mb-6">
          <v-subheader class="font-italic font-weight-light">
            <v-btn :disabled="minNumberMenu" class="mr-3" small fab @click="removeMenu()"
              ><v-icon>mdi-minus</v-icon></v-btn
            >
            <v-btn class="mr-3" small fab @click="addMenu()"
              ><v-icon>mdi-plus</v-icon></v-btn
            >
            <StandardColorPicker
              v-model="menuColor"
            ></StandardColorPicker>
            <p class="ma-3">Colore Menu</p></v-subheader
          >
        </v-row>
      </v-col>
    </v-row>
    <v-expansion-panels v-model="panel" flat class="pr-4">
      <v-expansion-panel v-model="panel" > 
        <v-expansion-panel-header hide-actions v-slot="{ open }">
          <v-row no-gutters>
            <v-col cols="8" class="text--secondary">
              <v-fade-transition leave-absolute>
                <span v-if="open" key="0"  class="font-italic font-weight-light"> Applica modifiche </span>
                <span v-else key="1"  class="font-italic font-weight-light"> Opzioni Menu </span>
              </v-fade-transition>
            </v-col>
          </v-row>
        </v-expansion-panel-header>
        <v-expansion-panel-content  >
          <v-checkbox
            hide-details
            class="ml-3 font-italic font-weight-light"
            v-model="deletable"
            label="Elimina"
          ></v-checkbox>
        </v-expansion-panel-content>
      </v-expansion-panel>
    </v-expansion-panels>
  </div>
</template>

<script>
import StandardColorPicker from "@/components/common/StandardColorPicker.vue";

export default {
  components: {
    StandardColorPicker,
  },
  props: {
    minNumberMenu: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      menuColor: "",
      deletable: false,
      panel:0
    };
  },
  mounted() {},
  methods: {
    addMenu() {
      this.$emit("add-menu");
    },
    removeMenu() {
      this.$emit("remove-menu");
    },
  },
  watch: {
    deletable(newVal) {
      this.$emit("deletable-menu", newVal);
    },
    menuColor(newVal) {
      this.$emit("change-color", newVal);
    }
  },
};
</script>
