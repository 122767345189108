<template>
  <div
    style="overflow-x: hidden; overflow-y: hidden"
  >
    <div>
      <v-btn v-if="selectedComponent !== ''" icon @click="onBackSettings">
        <v-icon>mdi-arrow-left</v-icon>
      </v-btn>
    </div>
    <div no-gutters class="mt-10 d-flex justify-center align-center">
      <div class="d-flex flex-column justify-center align-center" style="flex: 50%">
        <ResponsiveCardSelector
          v-if="!selectedCategory"
          :items="categoriesList"
          v-model="selectedCategory"
          :returnObject="true"
          item-key="name"
          card-max-height="200"
          card-min-height="200"
          card-max-width="200"
          card-min-width="200"
        >
          <template v-slot:item="{ item, cardMinHeight }">
            <div
              class="pa-2 d-flex flex-column justify-center align-center"
              :style="{
                'min-height':
                  cardMinHeight.indexOf('px') != -1
                    ? cardMinHeight
                    : cardMinHeight + 'px',
                'background-color': item.color,
              }"
            >
              <div class="mt-2 font-weight-medium" style="font-size: 150%">
                {{ item.name }}
              </div>
              <v-icon large>{{ item.icon }}</v-icon>
            </div>
          </template>
        </ResponsiveCardSelector>
      </div>
    </div>
    <component
      :class="{
        'section-fade-in': selectedComponent !== '',
      }"
      :is="selectedComponent"
    ></component>
  </div>
</template>

<script>
import ResponsiveCardSelector from "@/components/common/ResponsiveCardSelector.vue";
import ZoneManagementMinimalized from "@/components/bar/home/settings/ZoneManagementMinimalized.vue";
import MenuManagementMinimalized from "@/components/bar/home/settings/MenuManagementMinimalized.vue";

export default {
  name: "BarSettingsMinimalized",
  components: {
    ResponsiveCardSelector,
    ZoneManagementMinimalized,
    MenuManagementMinimalized,
  },
  data: function () {
    return {
      selectedCategory: undefined,
      categoriesList: [
        {
          name: "Zone",
          color: "#F4F4FA",
          component: "ZoneManagementMinimalized",
          icon: "mdi-table-picnic",
        },
        {
          name: "Menu",
          color: "#F4F4FA",
          component: "MenuManagementMinimalized",
          icon: "mdi-book-open-page-variant-outline",
        },
      ],
    };
  },
  methods: {
    onBackSettings() {
      this.selectedCategory = undefined;
      this.$emit("onExpandedChangeMinimalized", "settings");
    },
  },
  computed: {
    selectedComponent() {
      if (!!this.selectedCategory) {
        this.$emit(
          "onExpandedChangeMinimalized",
          this.selectedCategory.name.toLowerCase()
        );
        return this.selectedCategory.component;
      } else {
        return "";
      }
    },
  },
};
</script>