<template>
  <div style="overflow-x:hidden; overflow-y:hidden;" class="ml-2">
    <v-row no-gutters>
      <v-col cols="8">
        <ZoneList
          :internal-zone-counter.sync="internalZoneCounter"
          :external-zone-counter.sync="externalZoneCounter"
          :is-minimalized="true"
          :delete-zone="deletableZone"
          :bus="bus"
        ></ZoneList>
      </v-col>
      <v-col cols="4">
        <RightMenuSettingZone
          :min-number-internal-zones="internalZoneCounter <= 0"
          :min-number-external-zones="externalZoneCounter <= 0"
          :max-number-zones="(internalZoneCounter + externalZoneCounter) >= 26"
          @add-zone="handleOpenDialogCreateZone"
          @remove-zone="handleRemoveZone"
          @deletable-zone="handleDeletable"
          @change-color-internal="handleChangeColorInternal"
          @change-color-external="handleChangeColorExternal"
        ></RightMenuSettingZone>
      </v-col>
    </v-row>
    <ZoneCreateDialog
      v-if="openCreateZone"
      :open-dialog="openCreateZone"
      :zone-color-selected="colorSelected"
      :zone-internal="isInternal"
      :zone-counter="(internalZoneCounter + externalZoneCounter)"
      @close="close"
      @saved-zone="handleSaveZone"
    >
    </ZoneCreateDialog>
  </div>
</template>

<script>
import RightMenuSettingZone from "../../settings/zone/RightMenuSettingZone.vue";
import ZoneList from "../../settings/zone/ZoneList.vue";
import ZoneCreateDialog from "../../settings/zone/new/ZoneCreateDialog.vue";
import Vue from 'vue';
export default {
  name:"ZoneManagementMinimalized",
  components: {
    ZoneList,
    ZoneCreateDialog,
    RightMenuSettingZone,
  },
  data: function () {
    return {
      openCreateZone: false,
      deletableZone: false,
      colorInternalZone: "",
      colorExternalZone: "",
      colorSelected: "",
      internalZoneCounter: 0,
      externalZoneCounter: 0,
      isInternal: true,
      bus: new Vue()
    };
  },
  methods: {
    close() {
      if (this.isInternal) 
        this.internalZoneCounter--;
      else
        this.externalZoneCounter--;

      this.openCreateZone = false;
    },
    handleOpenDialogCreateZone: function (isInternal) {
      this.colorSelected = isInternal
        ? this.colorInternalZone
        : this.colorExternalZone;
      this.isInternal = isInternal;
      if (isInternal)
        this.internalZoneCounter++;
      else
        this.externalZoneCounter++;
      this.openCreateZone = true;
    },
    handleDeletable: function (value) {
      this.deletableZone = value;
    },
    handleRemoveZone: function (isInternal) {
      if (isInternal) {
        this.bus.$emit('remove-last-internal-zone');
      }
      else {
        this.bus.$emit('remove-last-external-zone');
      }
    },
    handleChangeColorInternal: function (newColor) {
      this.colorInternalZone = newColor;
    },
    handleChangeColorExternal: function (newColor) {
      this.colorExternalZone = newColor;
    },
    handleSaveZone:function () {
      this.openCreateZone = false;
      this.bus.$emit('reload-zones');
    }
  },
};
</script>

<style>
</style>