<template>
  <div style="overflow-x: hidden; overflow-y: hidden">
    <div>
      <v-row>
        <div class="ml-5 mt-3">
          <v-btn v-if="selectedComponent !== ''" icon @click="onBackSettings">
            <v-icon>mdi-arrow-left</v-icon>
          </v-btn>
        </div>
        <div class="mt-5 ml-2 font-italic font-weight-light">
          {{ toUpperCaseFirstLetter(expanded) }}
        </div>
      </v-row>
    </div>
    <div no-gutters class="mt-10 d-flex flex-row justify-center align-center">
      <div class="d-flex flex-column">
        <ResponsiveCardSelector
          v-if="!selectedCategory"
          :items="categoriesList"
          v-model="selectedCategory"
          :returnObject="true"
          item-key="name"
          card-max-height="300"
          card-min-height="300"
          card-max-width="300"
          card-min-width="300"
        >
          <template v-slot:item="{ item, cardMinHeight }">
            <div
              class="pa-2 d-flex flex-column justify-center align-center"
              :style="{
                'min-height':
                  cardMinHeight.indexOf('px') != -1
                    ? cardMinHeight
                    : cardMinHeight + 'px',
                'background-image': 'linear-gradient(#8a77e2, #50bfe1)',
              }"
            >
              <div class="mt-2 font-weight-medium" style="font-size: 150%">
                {{ item.name }}
              </div>
              <v-icon large>{{ item.icon }}</v-icon>
            </div>
          </template>
        </ResponsiveCardSelector>
      </div>
    </div>
    <component
      :class="{
        'section-fade-in': selectedComponent !== '',
      }"
      :is="selectedComponent"
    ></component>
  </div>
</template>

<script>
import ResponsiveCardSelector from "@/components/common/ResponsiveCardSelector.vue";
import ZoneManagement from "@/components/bar/settings/zone/ZoneManagement.vue";
import MenuManagement from "@/components/bar/settings/menu/MenuManagement.vue";
import GeneralManagement from "@/components/bar/settings/general/GeneralManagement.vue";

export default {
  name: "BarSettings",
  components: {
    ResponsiveCardSelector,
    ZoneManagement,
    MenuManagement,
    GeneralManagement,
  },
  data: function () {
    return {
      selectedCategory: undefined,
      categoriesList: [
        {
          name: "Zone",
          color: "#F4F4FA",
          component: "ZoneManagement",
          icon: "mdi-table-picnic",
        },
        {
          name: "Menu",
          color: "#F4F4FA",
          component: "MenuManagement",
          icon: "mdi-book-open-page-variant-outline",
        },
        /* {
          name: "Generali",
          color: "#F4F4FA",
          component: "GeneralManagement",
          icon: "mdi-cog",
        }, */
      ],
      expanded: "",
      originalUrl: window.location.pathname,
      defaultUrl: window.location.pathname,
    };
  },
  computed: {
    selectedComponent() {
      if (!!this.selectedCategory) {
        this.expanded = this.selectedCategory.name.toLowerCase();
        this.$emit("onExpandedChange", this.expanded);
        return this.selectedCategory.component;
      } else {
        return "";
      }
    },
  },
  methods: {
    onBackSettings() {
      this.selectedCategory = undefined;
      this.expanded = "";
      this.$emit("onExpandedChange", "settings");
    },
    toUpperCaseFirstLetter(string) {
      return string.substring(0, 1).toUpperCase() + string.substring(1);
    },
  },
  watch: {
    expanded(newVal) {
      try {
        if (newVal !== "") {
          window.history.pushState(
            "section/menu",
            "section/menu",
            this.originalUrl + "?section=settings" + "/" + newVal
          );
        } else {
          window.history.pushState(
            "default",
            "default",
            this.defaultUrl + "?section=settings"
          );
        }
      } catch (err) {
        console.log("try setting the section name but something goes wrong");
      }
    },
  },
};
</script>
